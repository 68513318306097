import * as React from 'react';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Input from '@mui/joy/Input';
import { editParcel } from '../../../sdk'; // Ajusta la ruta según tu estructura de proyecto

interface EditProps {
  open: boolean;
  onClose: () => void;
  parcelId: number | null;
  initialName: string;
  onEditSuccess: (newName: string) => void;
}

export default function EditParcel({ open, onClose, parcelId, initialName, onEditSuccess }: EditProps) {
  const [newName, setNewName] = React.useState(initialName);
  const [loading, setLoading] = React.useState(false);

  const handleEdit = async () => {
    if (parcelId !== null && newName.trim() !== '') {
      setLoading(true);
      try {
        await editParcel(parcelId, { name: newName });
        onEditSuccess(newName); // Notifica al padre que la edición fue exitosa con el nuevo nombre
        onClose(); // Cierra el modal
      } catch (error) {
        console.error('Error editing parcel name:', error);
        // Aquí podrías manejar el error, mostrando una notificación o similar
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog>
        <ModalClose />
        <Typography component="h5" mb={2}>
          Editar nombre de la parcela
        </Typography>
        <Box sx={{ mb: 2 }}>
          <Typography level="inherit" mb={1}>
            Cambia el nombre de la parcela a continuación:
          </Typography>
          <Input
            fullWidth
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Nuevo nombre de la parcela"
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button variant="plain" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="solid" onClick={handleEdit} disabled={loading || newName.trim() === ''}>
            {loading ? 'Guardando...' : 'Guardar'}
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
}
