import React, { useState, useEffect } from 'react';
import { Typography, Grid, Fab, Card, CardContent, CardActions, Tooltip, IconButton, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getFertilizers, Fertilizer, createFertilizer, deleteFertilizer, FertilizerResponse } from '../../sdk';
import AddFertilizer from './AddFertilizer';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius * 2,
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1.5, 2),
  borderTopLeftRadius: theme.shape.borderRadius * 2,
  borderTopRightRadius: theme.shape.borderRadius * 2,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const FertilizerComponent: React.FC = () => {
  const [fertilizers, setFertilizers] = useState<Fertilizer[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [fertilizerToDelete, setFertilizerToDelete] = useState<number | null>(null);

  const fetchFertilizers = async () => {
    try {
      const response: FertilizerResponse = await getFertilizers();
      setFertilizers(response.results);
    } catch (error) {
      console.error('Error al obtener los fertilizantes:', error);
    }
  };

  useEffect(() => {
    fetchFertilizers();
  }, []);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSubmit = async (formData: Omit<Fertilizer, 'id'>) => {
    try {
      await createFertilizer(formData);
      fetchFertilizers();
      handleCloseDialog();
    } catch (error) {
      console.error('Error al guardar el fertilizante:', error);
    }
  };

  const handleDeleteConfirm = (id: number) => {
    setFertilizerToDelete(id);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setFertilizerToDelete(null);
  };

  const handleDeleteConfirmed = async () => {
    if (fertilizerToDelete) {
      try {
        await deleteFertilizer(fertilizerToDelete);
        fetchFertilizers();
      } catch (error) {
        console.error('Error al eliminar el fertilizante:', error);
      }
    }
    setDeleteConfirmOpen(false);
    setFertilizerToDelete(null);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
        <Grid item>
          <Typography 
            variant="h5" 
            component="h2" 
            sx={{ 
              fontWeight: 'bold', 
              color: 'primary.main',
              borderBottom: '2px solid',
              borderColor: 'primary.main',
              paddingBottom: 1
            }}
          >
            Fertilizantes
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip title="Añadir Fertilizante">
            <Fab color="primary" size="small" onClick={handleOpenDialog} sx={{ bgcolor: 'success.light' }}>
              <AddIcon />
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {fertilizers.map((fertilizer) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={fertilizer.id}>
            <StyledCard>
              <CardHeader>
                <Typography 
                  variant="subtitle1" 
                  component="div" 
                  fontWeight="bold"
                  sx={{
                    flexGrow: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {fertilizer.commercial_name}
                </Typography>
              </CardHeader>
              <CardContent sx={{ flexGrow: 1, p: 2 }}>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  <strong>Tipo:</strong> {fertilizer.type}
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  <strong>Fabricante:</strong> {fertilizer.manufacturer}
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  <strong>Código:</strong> {fertilizer.code}
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  <strong>Fecha de registro:</strong> {fertilizer.registration_date}
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  <strong>Cantidad disponible:</strong> {fertilizer.available_quantity || '0'} Kg
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end', p: 1, pt: 0 }}>
                <Tooltip title="Eliminar">
                  <IconButton size="small" onClick={() => handleDeleteConfirm(fertilizer.id)} color="error">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </CardActions>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 2, p: 2, borderRadius: 1, bgcolor: 'background.paper', border: '1px solid', borderColor: 'divider' }}>
        <InfoOutlinedIcon sx={{ color: 'text.secondary', mr: 2, mt: 0.5 }} />
        <Typography variant="body2" color="text.secondary">
          Aquí puedes gestionar tus fertilizantes favoritos. Esta información te será útil para llevar un registro de los productos que utilizas en tus cultivos.
        </Typography>
      </Box>
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Confirmar eliminación?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estás seguro de que quieres eliminar este fertilizante? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirmed} color="error" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <AddFertilizer
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default FertilizerComponent;
