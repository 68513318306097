import * as React from 'react';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import HomeIcon from '@mui/icons-material/Home';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import MapIcon from '@mui/icons-material/Map';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import BrightnessAutoRoundedIcon from '@mui/icons-material/BrightnessAutoRounded'; // Asegúrate de importar este ícono
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LandscapeIcon from '@mui/icons-material/Landscape';
import DescriptionIcon from '@mui/icons-material/Description'; // Nuevo import para el icono de Documentos
import CloudUploadIcon from '@mui/icons-material/CloudUpload'; // Nuevo import para el icono de Subir documentos
import FolderIcon from '@mui/icons-material/Folder'; // Nuevo import para el icono de Tus documentos
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DataUsageIcon from '@mui/icons-material/DataUsage'; // Nuevo import para el icono de Datos
import LocalFloristIcon from '@mui/icons-material/LocalFlorist'; // Nuevo import para el icono de Cultivos
import ScienceIcon from '@mui/icons-material/Science'; // Nuevo import para el icono de Productos fitosanitarios
import PeopleIcon from '@mui/icons-material/People'; // Nuevo import para el icono de Colaboradores
import PestControlIcon from '@mui/icons-material/PestControl'; // Nuevo import para el icono de Productos fitosanitarios
import PersonIcon from '@mui/icons-material/Person';
import BuildIcon from '@mui/icons-material/Build'; // Nuevo import para el icono de Labores generales
import PestControlRoundedIcon from '@mui/icons-material/PestControlRounded'; // Nuevo import para el icono de Tratamiento fitosanitario
import LocalFloristRoundedIcon from '@mui/icons-material/LocalFloristRounded'; // Nuevo import para el icono de Tratamiento fertilizantes

interface SidebarProps {
  selectedSection: string;
  onSectionSelect: (section: string) => void;
  isMobileOpen: boolean;
  onClose: () => void;
}

export default function Sidebar({ selectedSection, onSectionSelect, isMobileOpen, onClose }: SidebarProps) {
  const [openSubMenus, setOpenSubMenus] = React.useState<Set<string>>(new Set());

  const handleSectionSelect = (section: string) => {
    onSectionSelect(section);
    if (isMobileOpen) {
      onClose();
    }
  };

  const toggleSubMenu = (section: string) => {
    setOpenSubMenus(prevOpenSubMenus => {
      const newOpenSubMenus = new Set(prevOpenSubMenus);
      if (newOpenSubMenus.has(section)) {
        newOpenSubMenus.delete(section);
      } else {
        newOpenSubMenus.add(section);
      }
      return newOpenSubMenus;
    });
  };

  const menuItems = [
    { label: 'Inicio', icon: <HomeIcon />, section: 'home' },
    { 
      label: 'Parcelas', 
      icon: <LandscapeIcon />, 
      section: 'parcelas',
      hasSubMenu: true,
      subItems: [
        { label: 'Listado', icon: <FormatListNumberedIcon />, section: 'parcelas' },
        { label: 'Mapa', icon: <MapIcon />, section: 'map' },
      ]
    },
    
  { 
      label: 'Trabajos', icon: <AgricultureIcon />, section: 'works',
      hasSubMenu: true,
      subItems: [
        { label: 'Labores generales', icon: <AgricultureIcon />, section: 'general_works' },
        { label: 'Tratamiento fitosanitario', icon: <PestControlRoundedIcon />, section: 'phytosanitary_treatment' },
        // { label: 'Tratamiento fertilizantes', icon: <ScienceIcon />, section: 'fertilizer_treatment' },
      ]
    },
    { 
      label: 'Tus Datos', 
      icon: <DataUsageIcon />,
      section: 'data',
      hasSubMenu: true,
      subItems: [
        { label: 'Mis datos', icon: <PersonIcon />, section: 'my_data' },
        { label: 'Cultivos', icon: <LocalFloristIcon />, section: 'fav_crops' },
        { label: 'Maquinaria', icon: <AgricultureIcon />, section: 'machinery' },
        { label: 'Productos fitosanitarios', icon: <PestControlIcon />, section: 'fav_pesticides' },
        { label: 'Fertilizantes', icon: <ScienceIcon />, section: 'fav_fertilizers' },
        // { label: 'Colaboradores', icon: <PeopleIcon />, section: 'collaborators' },
        
      ]
    },
    { 
      label: 'Cuaderno de campo', 
      icon: <NoteAltIcon />,
      section: 'ParcelNotebook', 
      hasSubMenu: true,
      subItems:  [
        { label: 'Ver cuaderno de campo',icon: <MenuBookIcon />, section: 'parcel_notebook' },
        // { label: 'Otra opción',icon: <FormatListNumberedIcon />, section: 'otra_opcion' },
      ]
    },
    { 
      label: 'Documentos', 
      icon: <DescriptionIcon />,
      section: 'documents',
      hasSubMenu: true,
      subItems: [
        { label: 'Subir documentos', icon: <CloudUploadIcon />, section: 'upload_documents' },
        { label: 'Tus documentos', icon: <FolderIcon />, section: 'your_documents' },
      ]
    },
  ];


  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        top: 0,
        left: 0,
        width: { xs: isMobileOpen ? 280 : 0, sm: 240 },
        height: '100vh',
        zIndex: 10000,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        borderRight: '1px solid',
        borderColor: 'divider',
        backgroundColor: 'background.paper',
        transition: 'width 0.3s',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 2 }}>
        <IconButton variant="soft" color="primary" size="sm">
          <BrightnessAutoRoundedIcon />
        </IconButton>
        <Typography level="title-lg">Cultiva Data</Typography>
      </Box>

      <List
        size="sm"
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          '--List-gap': '4px',
          '--ListItem-radius': (theme) => theme.vars.radius.sm,
          '& ul': { '--List-gap': '0px' },
          '& > li': { marginTop: '4px' },
          '& > li:first-of-type': { marginTop: 0 },
        }}
      >
        {menuItems.map((item) => (
          <ListItem
            key={item.section}
            nested={item.hasSubMenu}
            sx={{
              mb: item.hasSubMenu && openSubMenus.has(item.section) ? 0 : 1,
            }}
          >
            <ListItemButton
              selected={selectedSection === item.section}
              onClick={() => {
                if (item.hasSubMenu) {
                  toggleSubMenu(item.section);
                } else {
                  handleSectionSelect(item.section);
                }
              }}
              sx={{
                justifyContent: 'space-between',
                pr: 1,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', m: 0, p: 0 }}> {/* Eliminamos el margen aquí */}
                  {item.icon}
                </Box>
                <ListItemContent sx={{ ml: 2 }}>
                  <Typography level="body-sm">{item.label}</Typography>
                </ListItemContent>
              </Box>
              {item.hasSubMenu && (
                <ExpandMoreIcon
                  sx={{
                    transform: openSubMenus.has(item.section) ? 'rotate(-180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease',
                    ml: 1,
                  }}
                />
              )}
            </ListItemButton>
            {item.hasSubMenu && (
              <List
                sx={{
                  '--List-gap': '0px',
                  maxHeight: openSubMenus.has(item.section) ? '1000px' : '0px',
                  opacity: openSubMenus.has(item.section) ? 1 : 0,
                  transition: 'max-height 0.3s ease-in-out, opacity 0.3s ease-in-out',
                  pl: 3,
                  overflow: 'hidden',
                }}
              >
                {item.subItems.map((subItem) => (
                  <ListItem key={subItem.section}>
                    <ListItemButton
                      selected={selectedSection === subItem.section}
                      onClick={() => handleSectionSelect(subItem.section)}
                    >
                      <Box sx={{ fontSize: '0.8em', mr: 1 }}>
                        {subItem.icon}
                      </Box>
                      <ListItemContent>
                        <Typography level="body-xs">{subItem.label}</Typography>
                      </ListItemContent>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
          </ListItem>
        ))}
      </List>
    </Sheet>
  );
}
