import React, { useEffect, useState, FC } from 'react';
import { Route, Routes, useNavigate, Outlet } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { verifyToken  as VerifyTokenSDK} from '../../sdk';

interface PrivateRouteProps {
  redirectPath?: string;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ redirectPath = '/login' }) => {
  const cookies = new Cookies();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      const token = cookies.get('token'); // Obtener el token de la cookie

      if (!token) {
        setIsAuthenticated(false);
        return;
      }

      try {
        const response = await VerifyTokenSDK(token);
        if (response.status === 200) {
          setIsAuthenticated(true); // El token es válido
        } else {
          setIsAuthenticated(false); // El token no es válido
        }
      } catch (error) {
        setIsAuthenticated(false); // Error al verificar el token
      }
    };

    verifyToken();
  }, []);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate(redirectPath); // Redirige al usuario a la página de login si no está autenticado
    }
  }, [isAuthenticated, navigate, redirectPath]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Puedes mostrar un spinner de carga mientras se valida
  }

  return isAuthenticated ? <Outlet /> : null;
};

export default PrivateRoute;
