import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, Container, Box, Button } from '@mui/material';
import { getParcels, getLastWork } from '../../sdk';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import MapIcon from '@mui/icons-material/Map';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const Dashboard = () => {
  const [parcelCount, setParcelCount] = useState(0);
  const [totalArea, setTotalArea] = useState(0);
  const [lastWork, setLastWork] = useState<any | null>(null);
  const [parcels, setParcels] = useState<any[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const parcelsResponse = await getParcels(1);
        const lastWorkResponse = await getLastWork();
        setParcelCount(parcelsResponse.count);
        setParcels(parcelsResponse.results);
        setLastWork(lastWorkResponse);
        const totalAreaHectares = parcelsResponse.results.reduce((sum, parcel) => sum + parcel.area, 0) / 10000;
        setTotalArea(totalAreaHectares);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      }
    };

    fetchData();
  }, []);

  const getParcelName = (parcelId: number) => {
    const parcel = parcels.find(p => p.id === parcelId);
    return parcel ? parcel.name : 'Desconocida';
  };

  const CardHeader = ({ title, color }: { title: string, color: string }) => (
    <Box sx={{ 
      bgcolor: color, 
      color: 'white', 
      p: 1.5, 
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      textAlign: 'center',
      boxShadow: `0 2px 4px ${color}80`,
    }}>
      <Typography variant="subtitle2" sx={{ 
        fontWeight: 'bold', 
        fontSize: '0.85rem',
        textTransform: 'uppercase',
        letterSpacing: '0.5px'
      }}>
        {title}
      </Typography>
    </Box>
  );

  return (
    <Container maxWidth={false} sx={{ px: 2, py: 2 }}>
      <Grid container spacing={2}>
        {lastWork && (
          <Grid item xs={12}>
            <Card sx={{ borderRadius: 2, overflow: 'hidden', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
              <CardContent sx={{ bgcolor: '#ff9800', color: 'white', p: 2 }}>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <AgricultureIcon sx={{ fontSize: 24, mr: 1 }} />
                  Último Trabajo
                </Typography>
              </CardContent>
              <CardContent>
                <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}>
                  {lastWork.main_task}
                </Typography>
                <Typography variant="h6" sx={{ mb: 2, fontSize: '0.85rem', fontWeight: 'bold', textAlign: 'center' }}>
                  {lastWork.sub_task}
                </Typography>

                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', mb: 1, justifyContent: 'center' }}>
                  <MapIcon sx={{ color: '#4caf50', mr: 1 }} />
                  Parcela: {getParcelName(lastWork.parcel)}
                </Typography>
                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <CalendarTodayIcon sx={{ color: '#2196f3', mr: 1 }} />
                  Fecha: {new Date(lastWork.date).toLocaleDateString()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
        
        {parcelCount === 0 && totalArea === 0 ? (
          <Grid item xs={12}>
            <Card sx={{ 
              borderRadius: 4, 
              overflow: 'hidden', 
              boxShadow: '0 6px 12px rgba(211, 47, 47, 0.3)',
              bgcolor: '#ffebee', // Un rojo más suave para el fondo
              border: '2px solid #d32f2f', // Borde rojo
            }}>
              <CardContent sx={{ p: 3 }}>
                <Typography variant="h5" sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  color: '#b71c1c', // Rojo más oscuro para el texto
                  mb: 2,
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  textAlign: 'center', // Añadido para centrar el texto
                }}>
                  <AgricultureIcon sx={{ fontSize: 32, mr: 1, color: '#d32f2f' }} />
                  <span>¡No hay parcelas registradas!</span> {/* Envuelto en un span para mejor control */}
                </Typography>
                <Typography variant="body1" sx={{ 
                  textAlign: 'center', 
                  mb: 3,
                  color: '#d32f2f',
                  fontSize: '1.1rem',
                  fontWeight: 'medium',
                }}>
                  Ve al mapa AHORA y añade tu primera parcela.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  {/* El botón comentado se mantiene igual */}
                  {/* <Button
                    component={RouterLink}
                    to="/map"
                    variant="contained"
                    color="primary"
                    startIcon={<MapIcon />}
                  >
                    Ir al Mapa
                  </Button> */}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ) : (
          <>
            <Grid item xs={6}>
              <Card sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column', 
                borderRadius: 2,
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 6px 12px rgba(0,0,0,0.15)',
                },
              }}>
                <CardHeader title="Total de Parcelas" color="#4caf50" />
                <CardContent sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#4caf50' }}>
                    {parcelCount}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            
            <Grid item xs={6}>
              <Card sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column', 
                borderRadius: 2,
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: '0 6px 12px rgba(0,0,0,0.15)',
                },
              }}>
                <CardHeader title="Superficie (ha)" color="#2196f3" />
                <CardContent sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2196f3' }}>
                    {totalArea.toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}

        {/* Tarjetas placeholder */}
        {[1, 2, 3, 4, 5, 6].map((_, index) => (
          <Grid item xs={6} key={index}>
            <Card sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              borderRadius: 2,
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 6px 12px rgba(0,0,0,0.15)',
              },
            }}>
              <CardHeader title={`Placeholder ${index + 1}`} color="#9e9e9e" />
              <CardContent sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', p: 2 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#9e9e9e' }}>
                  Valor
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Dashboard;
