import React, { useState, useEffect } from 'react';
import { Typography, Grid, Fab, Card, CardContent, CardActions, Tooltip, IconButton, Box, Select, FormControl, SelectChangeEvent, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Alert, AlertColor } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MapIcon from '@mui/icons-material/Map';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getAllWorks, getWorksByParcelId, getParcels, Work, Parcel, deleteWork } from '../../sdk';
import AddWork from './AddNormalWork';
import CustomSnackbar from '../common/CustomSnackbar';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius * 2,
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1.5, 2),
  borderTopLeftRadius: theme.shape.borderRadius * 2,
  borderTopRightRadius: theme.shape.borderRadius * 2,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const FilterItem = styled(Box)(({ theme }) => ({
  flex: 1,
  minWidth: 0,
}));

const WorksList: React.FC = () => {
  const [works, setWorks] = useState<Work[]>([]);
  const [parcels, setParcels] = useState<Parcel[]>([]);
  const [selectedParcel, setSelectedParcel] = useState<string>('');
  const [isAddWorkModalOpen, setIsAddWorkModalOpen] = useState(false);
  const [isEditWorkModalOpen, setIsEditWorkModalOpen] = useState(false);
  const [selectedWork, setSelectedWork] = useState<Work | null>(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [workToDelete, setWorkToDelete] = useState<number | null>(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as AlertColor,
  });

  useEffect(() => {
    fetchWorks();
    fetchParcels();
  }, []);

  const fetchWorks = async () => {
    try {
      const response = await getAllWorks();
      console.log('Respuesta de getAllWorks:', response); // Añade este log
      if (response && response.results) {
        setWorks(response.results);
      } else {
        console.error('La respuesta de getAllWorks no tiene el formato esperado:', response);
        setWorks([]);
      }
    } catch (error) {
      console.error('Error al obtener los trabajos:', error);
      setWorks([]);
    }
  };

  const fetchParcels = async () => {
    try {
      const response = await getParcels(1);
      setParcels(response.results || []); // Asegúrate de manejar el caso de que results sea undefined
    } catch (error) {
      console.error('Error fetching parcels:', error);
      setParcels([]); // Establece un array vacío en caso de error
    }
  };

  const handleParcelSelect = async (event: SelectChangeEvent<string>) => {
    const parcelId = event.target.value;
    setSelectedParcel(parcelId);
    if (parcelId) {
      try {
        const response = await getWorksByParcelId(parseInt(parcelId));
        setWorks(response.results);
      } catch (error) {
        console.error('Error fetching works for parcel:', error);
      }
    } else {
      fetchWorks();
    }
  };

  const handleAddWorkClose = () => {
    setIsAddWorkModalOpen(false);
    fetchWorks();
  };

  const handleEditWork = (work: Work) => {
    setSelectedWork(work);
    setIsEditWorkModalOpen(true);
  };

  const handleEditWorkClose = () => {
    setIsEditWorkModalOpen(false);
    setSelectedWork(null);
    fetchWorks();
  };

  const handleDeleteConfirm = (id: number) => {
    setWorkToDelete(id);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setWorkToDelete(null);
  };

  const handleDeleteConfirmed = async () => {
    if (workToDelete) {
      try {
        await deleteWork(workToDelete);
        fetchWorks();
      } catch (error) {
        console.error('Error al eliminar el trabajo:', error);
      }
    }
    setDeleteConfirmOpen(false);
    setWorkToDelete(null);
  };

  const getParcelName = (parcelId: number) => {
    const parcel = parcels.find(p => p.id === parcelId);
    return parcel ? parcel.name : 'Desconocida';
  };

  const handleWorkAdded = (workType: string) => {
    setSnackbar({
      open: true,
      message: `Trabajo "${workType}" añadido correctamente.`,
      severity: 'success',
    });
    fetchWorks();
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
        <Grid item>
          <Typography 
            variant="h5" 
            component="h2" 
            sx={{ 
              fontWeight: 'bold', 
              color: 'primary.main',
              borderBottom: '2px solid',
              borderColor: 'primary.main',
              paddingBottom: 1
            }}
          >
            Trabajos
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip title="Añadir Trabajo">
            <Fab 
              color="primary" 
              size="small" 
              onClick={() => setIsAddWorkModalOpen(true)} 
              sx={{ bgcolor: 'success.light' }}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>

      <FilterContainer>
        <FilterItem>
          <Typography variant="body2" sx={{ mb: 1 }}>Filtrar por parcela:</Typography>
          <FormControl fullWidth size="small">
            <Select
              value={selectedParcel}
              onChange={handleParcelSelect}
              displayEmpty
              inputProps={{ 'aria-label': 'Seleccionar parcela' }}
            >
              <MenuItem value="">Todas las parcelas</MenuItem>
              {parcels.map((parcel) => (
                <MenuItem key={parcel.id} value={parcel.id.toString()}>{parcel.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </FilterItem>
      </FilterContainer>

      <Grid container spacing={2}>
        {works && works.length > 0 ? (
          works.map((work) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={work.id}>
              <StyledCard>
                <CardHeader>
                  <Typography 
                    variant="subtitle1" 
                    component="div" 
                    fontWeight="bold"
                    sx={{
                      flexGrow: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {work.main_task}
                  </Typography>
                </CardHeader>
                <CardContent sx={{ flexGrow: 1, p: 2 }}>
                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                    <strong>Subtarea:</strong> {work.sub_task || 'Sin subtarea'}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                    <strong>Parcela:</strong> {getParcelName(work.parcel)}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 0.5 }}>
                    <strong>Fecha:</strong> {new Date(work.date).toLocaleDateString()}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end', p: 1, pt: 0 }}>
                  <Tooltip title="Editar">
                    <IconButton size="small" onClick={() => handleEditWork(work)} color="primary">
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Eliminar">
                    <IconButton size="small" onClick={() => handleDeleteConfirm(work.id)} color="error">
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </CardActions>
              </StyledCard>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography>No hay trabajos disponibles.</Typography>
          </Grid>
        )}
      </Grid>

      <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 2, p: 2, borderRadius: 1, bgcolor: 'background.paper', border: '1px solid', borderColor: 'divider' }}>
        <InfoOutlinedIcon sx={{ color: 'text.secondary', mr: 2, mt: 0.5 }} />
        <Typography variant="body2" color="text.secondary">
          Aquí puedes ver y gestionar los trabajos realizados en tus parcelas. Utiliza el filtro para buscar trabajos específicos por parcela.
        </Typography>
      </Box>

      <AddWork
        open={isAddWorkModalOpen}
        onClose={handleAddWorkClose}
        parcel={parcels.find(p => p.id.toString() === selectedParcel) || null}
        onWorkAdded={handleWorkAdded}
      />

      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleSnackbarClose}
      />

      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Confirmar eliminación?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estás seguro de que quieres eliminar este trabajo? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirmed} color="error" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WorksList;
