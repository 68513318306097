import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Autocomplete, IconButton, Alert, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Pesticide } from '../../sdk';
import pesticideData from '../../data/fitosanitarios/fitosanitarios_nombres_small.json';

interface PesticideDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: Omit<Pesticide, 'id'>) => void;
  currentPesticide: Pesticide | null;
}

const AddPesticide: React.FC<PesticideDialogProps> = ({ open, onClose, onSubmit, currentPesticide }) => {
  const [formData, setFormData] = useState<Omit<Pesticide, 'id'>>({
    name: '',
    registration_number: '',
    formulado: '',
  });

  const [isValid, setIsValid] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (currentPesticide) {
      setFormData(currentPesticide);
    } else {
      setFormData({
        name: '',
        registration_number: '',
        formulado: '',
      });
    }
  }, [currentPesticide]);

  useEffect(() => {
    const isValidProduct = pesticideData.Productos.some(
      p => p.DATOSPRODUCTO.Nombre === formData.name && p.DATOSPRODUCTO.Num_Registro === formData.registration_number
    );
    setIsValid(isValidProduct);
    setShowWarning(!isValidProduct && formData.registration_number !== '');
  }, [formData]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    if (name === 'registration_number') {
      const matchingProduct = pesticideData.Productos.find(p => p.DATOSPRODUCTO.Num_Registro === value);
      if (matchingProduct) {
        setFormData(prev => ({
          ...prev,
          name: matchingProduct.DATOSPRODUCTO.Nombre,
          registration_number: value,
        }));
      }
    }
  };

  const handleSubmit = () => {
    onSubmit(formData);
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: { position: 'fixed', top: '10%', margin: 0 }
      }}
    >
      <DialogTitle>
        {currentPesticide ? 'Editar Pesticida' : 'Añadir Pesticida'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
          Puedes buscar por nombre o número de registro.
        </Typography>
        {showWarning && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            El número de registro no existe!
          </Alert>
        )}
        <Autocomplete
          options={pesticideData.Productos}
          getOptionLabel={(option) => `${option.DATOSPRODUCTO.Nombre} (${option.DATOSPRODUCTO.Num_Registro})`}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus
              margin="dense"
              name="name"
              label="Nombre del Pesticida"
              fullWidth
              variant="outlined"
            />
          )}
          value={pesticideData.Productos.find(p => p.DATOSPRODUCTO.Nombre === formData.name) || null}
          onChange={(event, newValue) => {
            if (newValue) {
              setFormData({
                ...formData,
                name: newValue.DATOSPRODUCTO.Nombre,
                registration_number: newValue.DATOSPRODUCTO.Num_Registro,
              });
            } else {
              setFormData({
                ...formData,
                name: '',
                registration_number: '',
              });
            }
          }}
        />
        <TextField
          margin="dense"
          name="registration_number"
          label="Número de Registro"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.registration_number}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button 
          onClick={handleSubmit} 
          disabled={!isValid}
          variant="contained"
          color="primary"
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPesticide;