import React from 'react';
import { Box, Button, Typography, Grid, IconButton, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MapIcon from '@mui/icons-material/Map';
import CropIcon from '@mui/icons-material/Grass';
import { Parcel } from '../parcel/ParcelContext';
import EditIcon from '@mui/icons-material/Edit';
import AgricultureIcon from '@mui/icons-material/Agriculture';

interface InfoMyParcelProps {
  open: boolean;
  onClose: () => void;
  selectedParcel: Parcel;
  onEdit: () => void;
  onAddWork: () => void;
}

const InfoMyParcel: React.FC<InfoMyParcelProps> = ({ open, onClose, selectedParcel, onEdit, onAddWork }) => {
  if (!open || !selectedParcel) return null;

  const firstCrop = selectedParcel.crops?.[0];

  const convertToHectares = (surface: number): number => {
    return parseFloat((surface / 10000).toFixed(2));
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: '20px 20px 0 0',
        boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
        maxHeight: '60vh',
        overflowY: 'auto',
        zIndex: 1300,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
        <Box>
          <Box display="flex" alignItems="center" mb={1}>
            <Typography variant="h6" fontWeight="bold" mr={2}>{selectedParcel.name}</Typography>
            <Tooltip title="Superficie de la parcela">
              <Box display="flex" alignItems="center" sx={{ bgcolor: 'rgba(0, 0, 0, 0.05)', borderRadius: 1, px: 1, py: 0.5 }}>
                <MapIcon fontSize="small" sx={{ mr: 0.5, color: 'primary.main' }} />
                <Typography variant="body2" fontWeight="medium">
                  {convertToHectares(selectedParcel.area)} ha
                </Typography>
              </Box>
            </Tooltip>
          </Box>
          <Box display="flex" alignItems="center">
            <CropIcon fontSize="small" sx={{ mr: 1, color: 'success.main' }} />
            <Typography variant="body1" fontWeight="medium">
              {firstCrop ? `${firstCrop.species} - ${firstCrop.variety || 'Sin variedad'}` : 'No especificado'}
            </Typography>
          </Box>
        </Box>
        <IconButton onClick={onClose} color="primary" sx={{ bgcolor: 'rgba(0, 0, 0, 0.05)' }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box mt={2} sx={{ display: 'flex', justifyContent: 'space-between', textAlign: 'center' }}>
        {['Polígono', 'Parcela', 'Agregado', 'Zona'].map((label) => (
          <Box key={label} sx={{ flex: 1 }}>
            <Typography variant="body2" fontWeight="bold">{label}</Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', textAlign: 'center' }}>
        {['poligono', 'parcela', 'agregado', 'zona'].map((key) => (
          <Box key={key} sx={{ flex: 1 }}>
            <Typography variant="body2">
              {Array.isArray(selectedParcel[key as keyof Parcel])
                ? 'Array'
                : String(selectedParcel[key as keyof Parcel] ?? '')}
            </Typography>
          </Box>
        ))}
      </Box>

      <Grid container spacing={1} mt={2}>
        <Grid item xs={6}>
          <Typography variant="body2" noWrap>
            <strong>Provincia:</strong> {selectedParcel.provincia}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" noWrap>
            <strong>Municipio:</strong> {selectedParcel.municipio}
          </Typography>
        </Grid>
      </Grid>

      <Box mt={3} display="flex" justifyContent="space-between">
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<EditIcon />}
          onClick={onEdit}
          sx={{ 
            flex: 1, 
            mr: 1, 
            py: 1.5, 
            borderRadius: 1,
            textTransform: 'none',
            fontSize: '1rem'
          }}
        >
          Editar Parcela
        </Button>
        <Button 
          variant="contained" 
          color="success" 
          startIcon={<AgricultureIcon />}
          onClick={onAddWork}
          sx={{ 
            flex: 1, 
            ml: 1, 
            py: 1.5, 
            borderRadius: 1,
            textTransform: 'none',
            fontSize: '1rem'
          }}
        >
          Añadir Trabajo
        </Button>
      </Box>
    </Box>
  );
};

export default InfoMyParcel;
