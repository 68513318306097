import React, { useState, useEffect } from 'react';
import { Typography, Grid, Fab, Card, CardContent, CardActions, Tooltip, IconButton, Box, Select, FormControl, SelectChangeEvent, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getPesticideWorks, getParcels, PesticideWork, Parcel, deletePesticideWork } from '../../sdk';
import AddPesticideWork from './AddPesticideWork';
import CustomSnackbar from '../common/CustomSnackbar';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius * 2,
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1.5, 2),
  borderTopLeftRadius: theme.shape.borderRadius * 2,
  borderTopRightRadius: theme.shape.borderRadius * 2,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const FilterItem = styled(Box)(({ theme }) => ({
  flex: 1,
  minWidth: 0,
}));

const PesticideWorksList: React.FC = () => {
  const [pesticideWorks, setPesticideWorks] = useState<PesticideWork[]>([]);
  const [parcels, setParcels] = useState<Parcel[]>([]);
  const [selectedParcel, setSelectedParcel] = useState<string>('');
  const [isAddWorkModalOpen, setIsAddWorkModalOpen] = useState(false);
  const [isEditWorkModalOpen, setIsEditWorkModalOpen] = useState(false);
  const [selectedWork, setSelectedWork] = useState<PesticideWork | null>(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [workToDelete, setWorkToDelete] = useState<number | null>(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error' | 'info' | 'warning',
  });

  useEffect(() => {
    fetchPesticideWorks();
    fetchParcels();
  }, []);

  const fetchPesticideWorks = async () => {
    try {
      const response = await getPesticideWorks();
      setPesticideWorks(response.results);
    } catch (error) {
      console.error('Error fetching pesticide works:', error);
    }
  };

  const fetchParcels = async () => {
    try {
      const response = await getParcels(1);
      setParcels(response.results);
    } catch (error) {
      console.error('Error fetching parcels:', error);
    }
  };

  const handleParcelSelect = async (event: SelectChangeEvent<string>) => {
    const parcelId = event.target.value;
    setSelectedParcel(parcelId);
    if (parcelId) {
      try {
        const response = await getPesticideWorks();
        setPesticideWorks(response.results.filter(work => work.parcel === parseInt(parcelId)));
      } catch (error) {
        console.error('Error fetching pesticide works for parcel:', error);
      }
    } else {
      fetchPesticideWorks();
    }
  };

  const handleAddWorkClose = (success?: boolean, message?: string) => {
    setIsAddWorkModalOpen(false);
    if (success) {
      setSnackbar({
        open: true,
        message: message || "Trabajo de pesticida añadido correctamente",
        severity: 'success',
      });
    }
    fetchPesticideWorks();
  };

  const handleEditWork = (work: PesticideWork) => {
    setSelectedWork(work);
    setIsEditWorkModalOpen(true);
  };

  const handleEditWorkClose = () => {
    setIsEditWorkModalOpen(false);
    setSelectedWork(null);
    fetchPesticideWorks();
  };

  const handleDeleteConfirm = (id: number) => {
    setWorkToDelete(id);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setWorkToDelete(null);
  };

  const handleDeleteConfirmed = async () => {
    if (workToDelete) {
      try {
        await deletePesticideWork(workToDelete);
        fetchPesticideWorks();
        setSnackbar({
          open: true,
          message: 'Trabajo de pesticida eliminado con éxito',
          severity: 'success',
        });
      } catch (error) {
        console.error('Error al eliminar el trabajo de pesticida:', error);
        setSnackbar({
          open: true,
          message: 'Error al eliminar el trabajo de pesticida',
          severity: 'error',
        });
      }
    }
    setDeleteConfirmOpen(false);
    setWorkToDelete(null);
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const getParcelName = (parcelId: number) => {
    const parcel = parcels.find(p => p.id === parcelId);
    return parcel ? parcel.name : 'Desconocida';
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
        <Grid item>
          <Typography 
            variant="h5" 
            component="h2" 
            sx={{ 
              fontWeight: 'bold', 
              color: 'primary.main',
              borderBottom: '2px solid',
              borderColor: 'primary.main',
              paddingBottom: 1
            }}
          >
            Trabajos de Pesticidas
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip title="Añadir Trabajo de Pesticida">
            <Fab 
              color="primary" 
              size="small" 
              onClick={() => setIsAddWorkModalOpen(true)} 
              sx={{ bgcolor: 'success.light' }}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>

      <FilterContainer>
        <FilterItem>
          <Typography variant="body2" sx={{ mb: 1 }}>Filtrar por parcela:</Typography>
          <FormControl fullWidth size="small">
            <Select
              value={selectedParcel}
              onChange={handleParcelSelect}
              displayEmpty
              inputProps={{ 'aria-label': 'Seleccionar parcela' }}
            >
              <MenuItem value="">Todas las parcelas</MenuItem>
              {parcels.map((parcel) => (
                <MenuItem key={parcel.id} value={parcel.id.toString()}>{parcel.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </FilterItem>
      </FilterContainer>

      <Grid container spacing={2}>
        {pesticideWorks.map((work) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={work.id}>
            <StyledCard>
              <CardHeader>
                <Typography 
                  variant="subtitle1" 
                  component="div" 
                  fontWeight="bold"
                  sx={{
                    flexGrow: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Trabajo de Pesticida
                </Typography>
              </CardHeader>
              <CardContent sx={{ flexGrow: 1, p: 2 }}>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  <strong>Parcela:</strong> {getParcelName(work.parcel)}
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  <strong>Fecha inicio:</strong> {new Date(work.start_date).toLocaleDateString()}
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  <strong>Fecha fin:</strong> {new Date(work.end_date).toLocaleDateString()}
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  <strong>Área tratada:</strong> {work.surface_area} m²
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  <strong>Plaga/Enfermedad:</strong> {work.pest_or_disease}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end', p: 1, pt: 0 }}>
                <Tooltip title="Editar">
                  <IconButton size="small" onClick={() => handleEditWork(work)} color="primary">
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Eliminar">
                  <IconButton size="small" onClick={() => handleDeleteConfirm(work.id)} color="error">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </CardActions>
            </StyledCard>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 2, p: 2, borderRadius: 1, bgcolor: 'background.paper', border: '1px solid', borderColor: 'divider' }}>
        <InfoOutlinedIcon sx={{ color: 'text.secondary', mr: 2, mt: 0.5 }} />
        <Typography variant="body2" color="text.secondary">
          Aquí puedes ver y gestionar los trabajos de pesticidas realizados en tus parcelas. Utiliza el filtro para buscar trabajos específicos por parcela.
        </Typography>
      </Box>

      <AddPesticideWork
        open={isAddWorkModalOpen}
        onClose={handleAddWorkClose}
        parcel={parcels.find(p => p.id.toString() === selectedParcel) || null}
      />

      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Confirmar eliminación?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estás seguro de que quieres eliminar este trabajo de pesticida? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirmed} color="error" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

export default PesticideWorksList;