import React, { useState, useEffect } from 'react';
import { Typography, Grid, Fab, Card, CardContent, CardActions, Tooltip, IconButton, Box, Menu, MenuItem, Select, FormControl, SelectChangeEvent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MapIcon from '@mui/icons-material/Map';
import CropIcon from '@mui/icons-material/Grass';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getParcels, Parcel, deleteParcel } from '../../sdk';
import EditParcel from '../parcel/actions/EditParcel';
import AddParcel from './AddParcel';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius * 2,
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1.5, 2),
  borderTopLeftRadius: theme.shape.borderRadius * 2,
  borderTopRightRadius: theme.shape.borderRadius * 2,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const FilterItem = styled(Box)(({ theme }) => ({
  flex: 1,
  minWidth: 0,
}));

const ParcelsList: React.FC = () => {
  const [parcels, setParcels] = useState<Parcel[]>([]);
  const [allParcels, setAllParcels] = useState<Parcel[]>([]);
  const [selectedCrop, setSelectedCrop] = useState<string>('');
  const [crops, setCrops] = useState<string[]>([]);
  const [isAddParcelModalOpen, setIsAddParcelModalOpen] = useState(false);
  const [isEditParcelModalOpen, setIsEditParcelModalOpen] = useState(false);
  const [selectedParcel, setSelectedParcel] = useState<Parcel | null>(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [parcelToDelete, setParcelToDelete] = useState<number | null>(null);

  useEffect(() => {
    fetchParcels();
  }, []);

  const fetchParcels = async () => {
    try {
      const parcelData = await getParcels(1);
      setParcels(parcelData.results);
      setAllParcels(parcelData.results);
      extractCrops(parcelData.results);
    } catch (error) {
      console.error('Error fetching parcels:', error);
    }
  };

  const extractCrops = (parcels: Parcel[]) => {
    const uniqueCrops = new Set<string>();
    parcels.forEach(parcel => {
      parcel.crops?.forEach(crop => {
        if (crop.species) {
          uniqueCrops.add(crop.species);
        }
      });
    });
    setCrops(Array.from(uniqueCrops));
  };

  const handleCropSelect = (event: SelectChangeEvent<string>) => {
    const crop = event.target.value;
    setSelectedCrop(crop);
    filterParcelsByCrop(crop);
  };

  const filterParcelsByCrop = (crop: string) => {
    if (crop === '') {
      setParcels(allParcels);
    } else {
      const filteredParcels = allParcels.filter(parcel => 
        parcel.crops && parcel.crops.some(c => c.species === crop)
      );
      setParcels(filteredParcels);
    }
  };

  const handleAddParcel = (parcelData: any) => {
    fetchParcels();
    setIsAddParcelModalOpen(false);
  };

  const handleEditParcel = (parcel: Parcel) => {
    setSelectedParcel(parcel);
    setIsEditParcelModalOpen(true);
  };

  const handleDeleteConfirm = (id: number) => {
    setParcelToDelete(id);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setParcelToDelete(null);
  };

  const handleDeleteConfirmed = async () => {
    if (parcelToDelete) {
      try {
        await deleteParcel(parcelToDelete);
        fetchParcels();
      } catch (error) {
        console.error('Error al eliminar la parcela:', error);
      }
    }
    setDeleteConfirmOpen(false);
    setParcelToDelete(null);
  };

  const convertToHectares = (surface: number): number => {
    return parseFloat((surface / 10000).toFixed(2));
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
        <Grid item>
          <Typography 
            variant="h5" 
            component="h2" 
            sx={{ 
              fontWeight: 'bold', 
              color: 'primary.main',
              borderBottom: '2px solid',
              borderColor: 'primary.main',
              paddingBottom: 1
            }}
          >
                        Parcelas ({parcels.length})
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip title="Añadir Parcela">
            <Fab color="primary" size="small" onClick={() => setIsAddParcelModalOpen(true)} sx={{ bgcolor: 'success.light' }}>
              <AddIcon />
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>
      
      <Box sx={{ mb: 3 }}>
        <Typography variant="body2" sx={{ mb: 1 }}>Filtrar por cultivo:</Typography>
        <FormControl fullWidth size="small">
          <Select
            value={selectedCrop}
            onChange={handleCropSelect}
            displayEmpty
            inputProps={{ 'aria-label': 'Seleccionar cultivo' }}
          >
            <MenuItem value="">Todos</MenuItem>
            {crops.map((crop) => (
              <MenuItem key={crop} value={crop}>{crop}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      
      <Grid container spacing={2}>
        {parcels.map((parcel) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={parcel.id}>
            <StyledCard>
              <CardHeader>
                <Typography 
                  variant="subtitle1" 
                  component="div" 
                  fontWeight="bold"
                  sx={{
                    flexGrow: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {parcel.name}
                </Typography>
                <Tooltip title="Superficie de la parcela">
                  <Box display="flex" alignItems="center" sx={{ bgcolor: 'rgba(255, 255, 255, 0.2)', borderRadius: 1, px: 1, py: 0.5 }}>
                    <MapIcon fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography variant="body2" fontWeight="medium">
                      {convertToHectares(parcel.area)} ha
                    </Typography>
                  </Box>
                </Tooltip>
              </CardHeader>
              <CardContent sx={{ flexGrow: 1, p: 2 }}>
                <Box display="flex" alignItems="center" mb={1}>
                  <CropIcon fontSize="small" sx={{ mr: 1, color: 'success.main' }} />
                  <Typography variant="body2" fontWeight="medium">
                    {parcel.crops?.[0] ? `${parcel.crops[0].species} - ${parcel.crops[0].variety || 'Sin variedad'}` : 'No especificado'}
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  <strong>Polígono:</strong> {parcel.poligono}
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  <strong>Parcela:</strong> {parcel.parcela}
                </Typography>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  <strong>Provincia:</strong> {parcel.provincia}
                </Typography>
                <Typography variant="body2">
                  <strong>Municipio:</strong> {parcel.municipio}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end', p: 1, pt: 0 }}>
                <Tooltip title="Editar">
                  <IconButton size="small" onClick={() => handleEditParcel(parcel)} color="primary">
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Eliminar">
                  <IconButton size="small" onClick={() => handleDeleteConfirm(parcel.id)} color="error">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </CardActions>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      
      <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 2, p: 2, borderRadius: 1, bgcolor: 'background.paper', border: '1px solid', borderColor: 'divider' }}>
        <InfoOutlinedIcon sx={{ color: 'text.secondary', mr: 2, mt: 0.5 }} />
        <Typography variant="body2" color="text.secondary">
          Aquí puedes gestionar tus parcelas. Esta información te será útil para llevar un registro de tus terrenos y planificar tus cultivos.
        </Typography>
      </Box>

      <AddParcel
        open={isAddParcelModalOpen}
        onClose={() => setIsAddParcelModalOpen(false)}
        onSave={handleAddParcel}
      />
      <EditParcel
        open={isEditParcelModalOpen}
        onClose={() => setIsEditParcelModalOpen(false)}
        parcelId={selectedParcel?.id || null}
        initialName={selectedParcel?.name || ''}
        onEditSuccess={fetchParcels}
      />
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Confirmar eliminación?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estás seguro de que quieres eliminar esta parcela? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirmed} color="error" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ParcelsList;

