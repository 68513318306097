import React, { useState, useEffect } from 'react';
import { Typography, Button, Select, MenuItem, FormControl, InputLabel, Stepper, Step, StepLabel, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SelectChangeEvent } from '@mui/material/Select';

const tiposDeArchivo = [
  { valor: 'documento', etiqueta: 'Documento' },
  { valor: 'imagen', etiqueta: 'Imagen' },
  { valor: 'otro', etiqueta: 'Otro' },
];

const UploadDocuments: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileType, setFileType] = useState('');
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (selectedFile) {
      setActiveStep(1);
    } else {
      setActiveStep(0);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (fileType) {
      setActiveStep(2);
    } else if (selectedFile) {
      setActiveStep(1);
    }
  }, [fileType, selectedFile]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
      setFileType(''); // Resetear el tipo de archivo cuando se selecciona uno nuevo
    }
  };

  const handleFileTypeChange = (event: SelectChangeEvent) => {
    setFileType(event.target.value as string);
  };

  const handleUpload = () => {
    // Lógica para subir el archivo
    console.log('Archivo a subir:', selectedFile);
    console.log('Tipo de archivo:', fileType);
  };

  return (
    <Box sx={{ p: 4, maxWidth: 500, mx: 'auto', mt: 4 }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}>
        Sube tus documentos
      </Typography>

      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {['Seleccionar archivo', 'Elegir tipo', 'Listo para subir'].map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={index < activeStep ? () => <CheckCircleIcon /> : undefined}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <input
        accept="*/*"
        style={{ display: 'none' }}
        id="contained-button-file"
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="contained-button-file">
        <Button
          variant="contained"
          component="span"
          sx={{ 
            mb: 2, 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mx: 'auto',
            padding: '16px',
          }}
        >
          <SearchIcon sx={{ fontSize: 40, mb: 1 }} />
          Seleccionar archivo
        </Button>
      </label>

      {selectedFile && (
        <Typography variant="body1" sx={{ mb: 2, fontWeight: 'medium', textAlign: 'center', wordBreak: 'break-word' }}>
          Archivo seleccionado: {selectedFile.name}
        </Typography>
      )}

      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="file-type-label">Tipo de archivo</InputLabel>
        <Select
          labelId="file-type-label"
          value={fileType}
          onChange={handleFileTypeChange}
          label="Tipo de archivo"
        >
          {tiposDeArchivo.map((tipo) => (
            <MenuItem key={tipo.valor} value={tipo.valor}>
              {tipo.etiqueta}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={activeStep < 2}
        startIcon={<LockIcon />}
        sx={{ mt: 2, display: 'block', mx: 'auto' }}
      >
        Subir archivo de forma segura
      </Button>

      <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 3, fontStyle: 'italic' }}>
        Tus documentos están protegidos. Utilizamos encriptación de extremo a extremo para garantizar la seguridad de tus archivos.
      </Typography>
    </Box>
  );
};

export default UploadDocuments;
