import React, { useState, useEffect } from 'react';
import { Typography, Grid, Fab, Chip, Tooltip, IconButton, Box, Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getFavouriteCrops, FavouriteCrop, deleteFavouriteCrop } from '../../sdk';
import AddCropDialog from '../map/AddCropDialog';

const FavouriteCrops: React.FC = () => {
  const [favouriteCrops, setFavouriteCrops] = useState<FavouriteCrop[]>([]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [cropToDelete, setCropToDelete] = useState<string | null>(null);

  const fetchFavouriteCrops = async () => {
    try {
      const response = await getFavouriteCrops();
      setFavouriteCrops(response.crops);
    } catch (error) {
      console.error('Error al obtener los cultivos favoritos:', error);
    }
  };

  useEffect(() => {
    fetchFavouriteCrops();
  }, []);

  const handleAddCrop = () => {
    setIsAddDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsAddDialogOpen(false);
  };

  const handleCropAdded = () => {
    fetchFavouriteCrops();
  };

  const handleDeleteConfirm = (cropName: string) => {
    setCropToDelete(cropName);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setCropToDelete(null);
  };

  const handleDeleteConfirmed = async () => {
    if (cropToDelete) {
      try {
        await deleteFavouriteCrop(cropToDelete);
        fetchFavouriteCrops();
      } catch (error) {
        console.error('Error al eliminar el cultivo favorito:', error);
      }
    }
    setDeleteConfirmOpen(false);
    setCropToDelete(null);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
        <Grid item>
          <Typography 
            variant="h5" 
            component="h2" 
            sx={{ 
              fontWeight: 'bold', 
              color: 'primary.main',
              borderBottom: '2px solid',
              borderColor: 'primary.main',
              paddingBottom: 1
            }}
          >
            Cultivos Favoritos
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip title="Añadir Cultivo">
            <Fab color="primary" size="small" onClick={handleAddCrop} sx={{ bgcolor: 'success.light' }}>
              <AddIcon />
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ mb: 3 }}>
        {favouriteCrops.map((crop, index) => (
          <Grid item key={index}>
            <Chip
              icon={<LocalFloristIcon sx={{ color: 'success.main' }} />}
              label={crop.name}
              onDelete={() => handleDeleteConfirm(crop.name)}
              deleteIcon={
                <IconButton size="small">
                  <DeleteIcon fontSize="small" />
                </IconButton>
              }
              sx={{
                bgcolor: 'background.paper',
                border: '1px solid',
                borderColor: 'success.light',
                '& .MuiChip-deleteIcon': {
                  color: 'text.secondary',
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 2, p: 2, borderRadius: 1, bgcolor: 'background.paper', border: '1px solid', borderColor: 'divider' }}>
        <InfoOutlinedIcon sx={{ color: 'text.secondary', mr: 2, mt: 0.5 }} />
        <Typography variant="body2" color="text.secondary">
          Cuando vayas a dar de alta una parcela, solo te mostraremos tus cultivos favoritos para que sea más sencillo el proceso.
        </Typography>
      </Box>
      <AddCropDialog
        open={isAddDialogOpen}
        onClose={handleCloseDialog}
        onCropAdded={handleCropAdded}
      />
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Confirmar eliminación?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estás seguro de que quieres eliminar este cultivo favorito? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirmed} color="error" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FavouriteCrops;
