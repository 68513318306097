import * as React from 'react';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from '../components/TwoSidedLayout';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate

export default function HeroLeft01() {
  const navigate = useNavigate(); // Inicializa useNavigate

  return (
    <TwoSidedLayout>
            <Typography
        fontWeight="bold"
        fontSize="clamp(2.5rem, 2rem + 3vw, 4rem)"
        textAlign="center"
        sx={{ marginBottom: '2rem', textTransform: 'uppercase', letterSpacing: '0.1em' }}
      >
        Cultivadata
      </Typography>

      <Typography color="primary" fontSize="lg" fontWeight="lg">
      </Typography>
      <Typography
      color="primary"
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
Impulsa tu cosecha al siguiente nivel.

</Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
      </Typography>
      <Button 
      
        size="lg" 
        endDecorator={<ArrowForward fontSize="large" />}
        onClick={() => navigate('/register')} // Navega a la ruta de registro
      >
        Regístrate
      </Button>
      <Typography>
        ¿Ya formas parte de nuestra comunidad? <Link fontWeight="lg" onClick={() => navigate('/login')}>Accede a tu cuenta</Link>
      </Typography>
      <Typography
        level="body-xs"
        sx={{
          position: 'absolute',
          top: '2rem',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
      </Typography>
    </TwoSidedLayout>
  );
}
