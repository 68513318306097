import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Box,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface AddParcelProps {
  open: boolean;
  onClose: () => void;
  onSave: (parcelData: any) => void;
}

const AddParcel: React.FC<AddParcelProps> = ({ open, onClose, onSave }) => {
  const [parcelData, setParcelData] = useState({
    name: '',
    provincia: '',
    municipio: '',
    agregado: '',
    zona: '',
    poligono: '',
    parcela: ''
  });
  const [errors, setErrors] = useState<{[key: string]: string}>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'name') {
      setParcelData({ ...parcelData, [name]: value });
    } else {
      const numericValue = value.replace(/\D/g, '');
      setParcelData({ ...parcelData, [name]: numericValue });
    }
    setErrors({ ...errors, [name]: '' });
  };

  const validateFields = () => {
    const newErrors: {[key: string]: string} = {};
    Object.keys(parcelData).forEach(key => {
      if (parcelData[key as keyof typeof parcelData] === '') {
        newErrors[key] = 'Este campo es obligatorio';
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (validateFields()) {
      onSave(parcelData);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Añadir Nueva Parcela</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Nombre de la parcela"
          type="text"
          fullWidth
          variant="outlined"
          value={parcelData.name}
          onChange={handleChange}
          placeholder="Pon un nombre para identificarla"
          error={!!errors.name}
          helperText={errors.name}
          required
        />
        <TextField
          margin="dense"
          name="provincia"
          label="Provincia"
          type="tel"
          fullWidth
          variant="outlined"
          value={parcelData.provincia}
          onChange={handleChange}
          placeholder="Ej: 49"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          error={!!errors.provincia}
          helperText={errors.provincia}
          required
        />
        <TextField
          margin="dense"
          name="municipio"
          label="Municipio"
          type="tel"
          fullWidth
          variant="outlined"
          value={parcelData.municipio}
          onChange={handleChange}
          placeholder="Ej: 34"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          error={!!errors.municipio}
          helperText={errors.municipio}
          required
        />
        <TextField
          margin="dense"
          name="agregado"
          label="Agregado"
          type="tel"
          fullWidth
          variant="outlined"
          value={parcelData.agregado}
          onChange={handleChange}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          error={!!errors.agregado}
          helperText={errors.agregado}
          required
        />
        <TextField
          margin="dense"
          name="zona"
          label="Zona"
          type="tel"
          fullWidth
          variant="outlined"
          value={parcelData.zona}
          onChange={handleChange}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          error={!!errors.zona}
          helperText={errors.zona}
          required
        />
        <TextField
          margin="dense"
          name="poligono"
          label="Polígono"
          type="tel"
          fullWidth
          variant="outlined"
          value={parcelData.poligono}
          onChange={handleChange}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          error={!!errors.poligono}
          helperText={errors.poligono}
          required
        />
        <TextField
          margin="dense"
          name="parcela"
          label="Parcela"
          type="tel"
          fullWidth
          variant="outlined"
          value={parcelData.parcela}
          onChange={handleChange}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          error={!!errors.parcela}
          helperText={errors.parcela}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddParcel;