import React, { useState } from 'react';
import { Box, Drawer, AppBar, Toolbar, Typography, IconButton, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from '../sidebar/SideBar';
import MapComponent from '../map/Map';
import { ParcelProvider } from '../parcel/ParcelContext';
import ListParcels from '../list_parcels/App';
import Home from '../home/App';
import WorksList from '../works/NormalWork';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { IconButton as IconButtonJoy } from '@mui/joy';
import ParcelNotebook from '../parcel_notebook/App';
import UploadDocuments from '../documents/UploadDocuments';
import FavouriteCrops from '../favourites/Crops';
import FavouriteMachinery from '../favourites/Machinery';
import PesticideComponent from '../favourites/Pesticide';
import FertilizerComponent from '../favourites/Fertilizer';
import PesticideWork from '../works/PesticideWork';
import MyData from '../favourites/MyData';

const Dashboard = () => {
  const [selectedSection, setSelectedSection] = useState<string>('home');
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const cookies = new Cookies();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const renderContent = () => {
    switch (selectedSection) {
      case 'home':
        return <Home />;
      case 'parcelas':
        return <ListParcels />;
      case 'map':
        return (
          <ParcelProvider>
            <MapComponent />
          </ParcelProvider>
        );
      case 'general_works':
        return <WorksList />;
      case 'parcel_notebook':
        return <ParcelNotebook />;
      case 'my_data':
        return <MyData />;
      case 'fav_crops':
        return <FavouriteCrops />;
      case 'fav_pesticides':
        return <PesticideComponent />;
      case 'upload_documents':
        return <UploadDocuments />;
      case 'machinery':
        return <FavouriteMachinery />;
      case 'fav_fertilizers':
        return <FertilizerComponent />;
      case 'phytosanitary_treatment':
        return <PesticideWork />;
      default:
        return <ListParcels />;
    }
  };

  const drawerWidth = 240;

  const handleLogout = async () => {
    cookies.remove('token', { path: '/' });
    navigate('/login');
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Cultivadata
          </Typography>
          {/* <Box sx={{ ml: 'auto' }}>
            <IconButtonJoy size="sm" variant="plain" color="neutral" onClick={handleLogout}>
              <LogoutRoundedIcon />
            </IconButtonJoy>
          </Box> */}
        </Toolbar>
      </AppBar>

      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Sidebar
          selectedSection={selectedSection}
          onSectionSelect={setSelectedSection}
          isMobileOpen={mobileOpen}
          onClose={handleDrawerToggle}
        />
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Toolbar />
        <Box sx={{ flexGrow: 1, p: 2 }}>
          {renderContent()}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
