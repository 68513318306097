import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Autocomplete, Slide } from '@mui/material';
import { addFavouriteCrop } from '../../sdk';
import especies from '../../data/cultivos/especies.json';
import { TransitionProps } from '@mui/material/transitions';


interface AddCropDialogProps {
  open: boolean;
  onClose: () => void;
  onCropAdded: () => void;
}

interface Especie {
  name: string;
  id: number;
}


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AddCropDialog: React.FC<AddCropDialogProps> = ({ open, onClose, onCropAdded }) => {
  const [selectedCrop, setSelectedCrop] = useState<Especie | null>(null);

  const handleCropSelect = async (crop: Especie | null) => {
    if (crop) {
      try {
        await addFavouriteCrop(crop.name);
        onCropAdded();
        onClose();
      } catch (error) {
        console.error('Error al añadir el cultivo favorito:', error);
        // Aquí podrías mostrar un mensaje de error al usuario
      }
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          position: 'absolute',
          top: '10%',
          margin: 0,
          maxHeight: '90%',
          height: 'auto',
          overflow: 'visible',
        },
      }}
    >
      <DialogTitle>Nuevo cultivo</DialogTitle>
      <DialogContent>
        <Autocomplete<Especie, false, false, false>
          options={especies as Especie[]}
          renderInput={(params) => <TextField {...params} label="Buscar cultivo" />}
          value={selectedCrop}
          onChange={(event, newValue) => setSelectedCrop(newValue)}
          getOptionLabel={(option) => option.name}
          fullWidth
          autoComplete
          autoHighlight
          disableListWrap
          ListboxProps={{
            style: {
              maxHeight: '40vh',
              overflow: 'auto',
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={() => handleCropSelect(selectedCrop)} disabled={!selectedCrop}>
          Añadir
        </Button>
      </DialogActions>
    </Dialog>
  );
};


export default AddCropDialog;