import React, { useState } from 'react';
import { generateWorkfieldPDF } from '../../sdk';
import { Button, Typography } from '@mui/material';

function ParcelNotebook() {
    const [isLoading, setIsLoading] = useState(false);
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);

    const handleGeneratePDF = async () => {
        setIsLoading(true);
        try {
            const pdfBlob = await generateWorkfieldPDF();
            const url = URL.createObjectURL(pdfBlob);
            setPdfUrl(url);
        } catch (error) {
            console.error('Error al generar el PDF:', error);
            alert('Hubo un error al generar el PDF. Por favor, inténtalo de nuevo.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleViewPDF = () => {
        if (pdfUrl) {
            window.open(pdfUrl, '_blank');
        }
    };

    return (
        <div>
            <Button 
                variant="contained" 
                color="primary" 
                onClick={handleGeneratePDF}
                disabled={isLoading}
            >
                {isLoading ? 'Generando PDF...' : 'Generar Cuaderno de Campo (PDF)'}
            </Button>
            
            {pdfUrl && (
                <div style={{marginTop: '20px'}}>
                    <Typography variant="body1">
                        El PDF del Cuaderno de Campo ha sido generado.
                    </Typography>
                    <Button 
                        variant="outlined" 
                        color="primary" 
                        onClick={handleViewPDF}
                        style={{marginTop: '10px'}}
                    >
                        Ver PDF
                    </Button>
                </div>
            )}
        </div>
    );
}

export default ParcelNotebook;

