import React, { createContext, useState, ReactNode } from 'react';

// Define la interfaz para una parcela
export interface Parcel {
  id: number;
  name: string;
  area: number;
  coordinates: number[][];
  agromonitoring_id?: string;
  crops?: Crop[];
  provincia?: number;
  municipio?: number;
  agregado?: number;
  zona?: number;
  poligono?: number;
  parcela?: number;
  superficie?: number;


}


  export interface Crop {
    id: number;
    parcel: number;  // ID de la parcela asociada
    species: string;
    variety?: string;
    irrigation_type?: string;
    environment?: string;
  }



// Define la interfaz para el contexto de parcelas
interface ParcelContextType {
  selectedParcel: Parcel | null;
  setSelectedParcel: (parcel: Parcel | null) => void;
}

// Crea el contexto con el tipo definido
export const ParcelContext = createContext<ParcelContextType | undefined>(undefined);

export const ParcelProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [selectedParcel, setSelectedParcel] = useState<Parcel | null>(null);

  return (
    <ParcelContext.Provider value={{ selectedParcel, setSelectedParcel }}>
      {children}
    </ParcelContext.Provider>
  );
};

// Hook personalizado para usar el contexto de forma segura
export const useParcelContext = () => {
  const context = React.useContext(ParcelContext);
  if (!context) {
    throw new Error('useParcelContext debe usarse dentro de un ParcelProvider');
  }
  return context;
};
