import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Autocomplete, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Fertilizer } from '../../sdk';
import fertilizerData from '../../data/fertilizantes/fertilizantes.json';

interface FertilizerDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: Omit<Fertilizer, 'id'>) => void;
}

const AddFertilizer: React.FC<FertilizerDialogProps> = ({ open, onClose, onSubmit }) => {
  const [formData, setFormData] = useState<Omit<Fertilizer, 'id'>>({
    commercial_name: '',
    type: '',
    manufacturer: '',
    code: '',
    registration_date: new Date().toISOString().split('T')[0], // Fecha actual como string en formato YYYY-MM-DD
    available_quantity: 0,
  });

  const [manufacturers, setManufacturers] = useState<string[]>([]);
  const [products, setProducts] = useState<any[]>([]);

  useEffect(() => {
    const uniqueManufacturers = Array.from(new Set(fertilizerData.map(item => item.fabricante)));
    setManufacturers(uniqueManufacturers);
  }, []);

  const handleManufacturerChange = (event: React.ChangeEvent<{}>, value: string | null) => {
    if (value) {
      setFormData(prev => ({ ...prev, manufacturer: value }));
      const manufacturerProducts = fertilizerData.filter(item => item.fabricante === value);
      setProducts(manufacturerProducts);
    } else {
      setFormData(prev => ({ ...prev, manufacturer: '', commercial_name: '', type: '', code: '' }));
      setProducts([]);
    }
  };

  const handleProductChange = (event: React.ChangeEvent<{}>, value: any | null) => {
    if (value) {
      setFormData({
        commercial_name: value.nombre_comercial,
        type: value.tipo,
        manufacturer: value.fabricante,
        code: value.codigo,
        registration_date: new Date().toISOString().split('T')[0] // Fecha actual como string en formato YYYY-MM-DD
        
      });
    } else {
      setFormData(prev => ({ ...prev, commercial_name: '', type: '', code: '' }));
    }
  };

  const handleSubmit = () => {
    onSubmit(formData);
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: { position: 'fixed', top: '10%', margin: 0 }
      }}
    >
      <DialogTitle>
        Añadir Fertilizante
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          options={manufacturers}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus
              margin="dense"
              label="Fabricante"
              fullWidth
              variant="outlined"
            />
          )}
          value={formData.manufacturer || null}
          onChange={handleManufacturerChange}
        />
        <Autocomplete
          options={products}
          getOptionLabel={(option) => option.nombre_comercial}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label="Nombre del Fertilizante"
              fullWidth
              variant="outlined"
            />
          )}
          value={products.find(p => p.nombre_comercial === formData.commercial_name) || null}
          onChange={handleProductChange}
          disabled={!formData.manufacturer}
        />
        <TextField
          margin="dense"
          name="type"
          label="Tipo"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.type}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          margin="dense"
          name="code"
          label="Código"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.code}
          InputProps={{
            readOnly: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFertilizer;