import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Autocomplete, IconButton, Typography, Box, Grid, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createWork, getParcels, Parcel } from '../../sdk';
import { getParcelData, MunicipioInfo } from '../parcel/ParcelData';
import CustomSnackbar from '../common/CustomSnackbar';
import { AlertColor } from '@mui/material';

// Define las tareas y subtareas
const laborOptions = {
  Cosecha: [],
  Laboreo: ['Arado', 'Grada', 'Rodillo', 'Otros'],
  Pastoreo: ['Bovidos', 'Cerdos', 'Ovinos', 'Carpinos', 'Equidos'],
  Poda: [],
  Riego: [],
  Siega: [],
  Siembra: ['Directa', 'Convencional'],
};

interface AddWorkModalProps {
  open: boolean;
  onClose: () => void;
  parcel?: Parcel | null;
  onWorkAdded: (workType: string) => void; // Nueva prop
}

const AddWork: React.FC<AddWorkModalProps> = ({ open, onClose, parcel: initialParcel, onWorkAdded }) => {
  console.log("AddWork renderizado. initialParcel:", initialParcel);

  const [formData, setFormData] = useState(() => {
    console.log("Inicializando formData. initialParcel:", initialParcel);
    return {
      date: new Date().toISOString().split('T')[0],
      labor: '',
      subTask: '',
      parcel: initialParcel || null,
    };
  });

  console.log("Estado formData después de inicialización:", formData);

  const [parcels, setParcels] = useState<Parcel[]>([]);
  const [parcelData, setParcelData] = useState<MunicipioInfo | null>(null);
  const [errors, setErrors] = useState<{[key: string]: boolean}>({});
  const [selectedSubTask, setSelectedSubTask] = useState<string>('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as AlertColor,
  });

  useEffect(() => {
    console.log("useEffect [open, initialParcel] ejecutado. open:", open, "initialParcel:", initialParcel);
    if (open) {
      if (initialParcel) {
        console.log("Actualizando formData con initialParcel:", initialParcel);
        setFormData(prev => ({ ...prev, parcel: initialParcel }));
      } else {
        console.log("Fetching parcels porque no hay initialParcel");
        fetchParcels();
      }
    }
  }, [open, initialParcel]);

  useEffect(() => {
    if (formData.parcel && formData.parcel.provincia && formData.parcel.municipio) {
      const data = getParcelData({
        provincia: formData.parcel.provincia,
        municipio: formData.parcel.municipio
      });
      setParcelData(data);
    } else {
      setParcelData(null);
    }
  }, [formData.parcel]);

  const fetchParcels = async () => {
    try {
      const response = await getParcels(1);
      setParcels(response.results);
    } catch (error) {
      console.error('Error fetching parcels:', error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: false }));
  };

  const handleLaborSelect = (labor: string) => {
    setFormData(prev => ({ ...prev, labor, subTask: '' }));
    setErrors(prev => ({ ...prev, labor: false }));
  };

  const handleSubTaskSelect = (subTask: string) => {
    setSelectedSubTask(subTask);
    setFormData(prev => ({ ...prev, subTask }));
  };

  const validateForm = () => {
    const newErrors: {[key: string]: boolean} = {};
    if (!formData.parcel) newErrors.parcel = true;
    if (!formData.labor) newErrors.labor = true;
    if (!formData.date) newErrors.date = true;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    console.log("handleSubmit ejecutado. formData:", formData);
    console.log(formData);
    console.log("AAA")
    if (!validateForm()) {
      return;
    }

    const workData = {
      parcel: formData.parcel!.id,
      main_task: formData.labor,
      sub_task: formData.subTask || null,
      date: formData.date,
    };

    try {
      await createWork(workData);
      setSnackbar({
        open: true,
        message: `Trabajo "${formData.labor}" añadido correctamente.`,
        severity: 'success',
      });
      onWorkAdded(formData.labor);
      onClose();
    } catch (error) {
      console.error('Error al guardar el trabajo:', error);
      setSnackbar({
        open: true,
        message: 'Hubo un error al guardar el trabajo. Inténtalo de nuevo.',
        severity: 'error',
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const renderSubTasks = () => {
    if (!formData.labor || laborOptions[formData.labor as keyof typeof laborOptions].length === 0) {
      return null;
    }

    return (
      <>
        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>Selecciona una subtarea:</Typography>
        <Grid container spacing={1}>
          {laborOptions[formData.labor as keyof typeof laborOptions].map((subTask) => (
            <Grid item key={subTask}>
              <Button
                variant={selectedSubTask === subTask ? "contained" : "outlined"}
                color={selectedSubTask === subTask ? "success" : "primary"}
                onClick={() => handleSubTaskSelect(subTask)}
              >
                {subTask}
              </Button>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <>
      <Dialog 
        open={open} 
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: { position: 'fixed', top: '10%', margin: 0 }
        }}
      >
        <DialogTitle>
          Añadir trabajo
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Parcela seleccionada: {formData.parcel ? JSON.stringify(formData.parcel.name) : 'Ninguna'}
          </Typography>
          {!initialParcel && (
            <Autocomplete
              options={parcels}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  name="parcel"
                  label="Selecciona una parcela"
                  fullWidth
                  variant="outlined"
                  error={errors.parcel}
                  helperText={errors.parcel ? "Este campo es obligatorio" : ""}
                />
              )}
              value={formData.parcel}
              onChange={(_, newValue) => {
                setFormData(prev => ({ ...prev, parcel: newValue }));
                setErrors(prev => ({ ...prev, parcel: false }));
              }}
            />
          )}
          <TextField
            margin="dense"
            name="date"
            label="Fecha"
            type="date"
            fullWidth
            variant="outlined"
            value={formData.date}
            onChange={handleInputChange}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                style: { cursor: 'pointer' }
              },
              onClick: (event) => {
                (event.target as HTMLElement).querySelector('input')?.showPicker();
              }
            }}
            error={errors.date}
            helperText={errors.date ? "Este campo es obligatorio" : ""}
          />

          <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>Selecciona una labor:</Typography>
          <Grid container spacing={1}>
            {Object.keys(laborOptions).map((labor) => (
              <Grid item key={labor}>
                <Button
                  variant={formData.labor === labor ? "contained" : "outlined"}
                  color={formData.labor === labor ? "success" : "primary"}
                  onClick={() => handleLaborSelect(labor)}
                >
                  {labor}
                </Button>
              </Grid>
            ))}
          </Grid>
          {errors.labor && (
            <Typography color="error" variant="caption" sx={{ mt: 1 }}>
              Debes seleccionar una labor
            </Typography>
          )}
          {renderSubTasks()}

        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">Guardar</Button>
        </DialogActions>
      </Dialog>
      
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

export default AddWork;
