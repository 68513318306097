import axios, { AxiosResponse, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

// Configuración de la instancia de Axios
const apiClient: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor para añadir el token de autorización a todas las solicitudes
apiClient.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const token = cookies.get('token'); // Obtén el token de las cookies
      if (token && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
// Funciones del SDK
interface UserInfo {
  id: string;
  username: string;
  email: string;
  // Otros campos que defina tu API...
}

export const getUserInfo = async (): Promise<UserInfo> => {
  const response = await apiClient.get<UserInfo>('/auth/users/me/');
  return response.data;
};

interface LoginData {
    email: string;
    password: string;
  }
  
  interface TokenResponse {
    access: string;
    refresh: string;
  }
  
  export const createToken = async (data: LoginData): Promise<TokenResponse> => {
    const response: AxiosResponse<TokenResponse> = await apiClient.post('/auth/jwt/create/', {
      username: data.email,
      password: data.password,
    });
    return response.data;
  };
  
  interface VerifyTokenResponse {
    token: string;
  }
  
  export const verifyToken = async (token: string): Promise<AxiosResponse<any>> => {
    const response = await apiClient.post('/auth/jwt/verify/', {
      token,
    });
    return response;
  };
  
  
  interface RegisterData {
    email: string;
    password: string;
  }
  
  export const registerUser = async (data: RegisterData): Promise<AxiosResponse<any>> => {
    const response = await apiClient.post('/auth/users/', {
      username: data.email,
      email: data.email,
      password: data.password,
    });
    return response;
  };
  
  export interface Crop {
    id: number;
    parcel: number;  // ID de la parcela asociada
    species: string;
    variety?: string;
    irrigation_type?: string;
    environment?: string;
  }
  
  
  export interface Parcel {
    id: number;
    name: string;
    area: number;
    coordinates: number[][];
    agromonitoring_id?: string;
    crops?: Crop[];
    provincia?: number;
    municipio?: number;
    agregado?: number;
    zona?: number;
    poligono?: number;
    parcela?: number;
    superficie?: number;


  }

  export interface ParcelResponse {
    results: Parcel[];
    next: string | null;
    previous: string | null;
    count: number;
  }
  
 
    
  
  export interface ParcelData {
    coordinates: any;
    area: number;
    name: string;
    agromonitoring_id: string;
    provincia?: number;
    municipio?: number;
    agregado?: number;
    zona?: number;
    poligono?: number;
    parcela?: number;
    superficie?: number;
}

  
// PARCELS API

  export const createParcel = async (data: ParcelData): Promise<AxiosResponse<any>> => {
    console.log(data);
    console.log("AA")
    const response = await apiClient.post('/parcels/', data, {
    });
    console.log(response.data);
    return response; // Devuelve la respuesta completa de Axios
  };
  
  export const deleteParcel = async (id: number): Promise<AxiosResponse<any>> => {
    const response = await apiClient.delete(`/parcels/${id}/`);
    return response; // Devuelve la respuesta completa de Axios
  };

  export const editParcel = async (id: number, data: Partial<{ name: string }>): Promise<AxiosResponse<any>> => {
    const response = await apiClient.patch(`/parcels/${id}/`, data);
    return response; // Devuelve la respuesta completa de Axios
  };
  

  export const getParcels = async (page: number = 1): Promise<ParcelResponse> => {
    const response: AxiosResponse<ParcelResponse> = await apiClient.get('/parcels/', {
      params: {
        page: page,
      },
    });
    
    // Devuelve directamente la data del response, que es de tipo ParcelResponse
    return response.data;
  };
  
  

  export const registerAndLogin = async (data: RegisterData): Promise<TokenResponse> => {
    // 1. Registrar al usuario
    await registerUser(data);
  
    // 2. Hacer login automáticamente después del registro exitoso
    const loginResponse = await createToken(data);
  
    return loginResponse;
  };
  


  export interface DrawParcelsParams {
    x: number;
    y: number;
  }

  export const getDrawParcels = async (params: DrawParcelsParams): Promise<any> => {
    const response: AxiosResponse<any> = await apiClient.post('/parcels/generate_draw_parcels/', params);
    // Devuelve directamente la data del response, que es de tipo any
    return response;
  };
  


  export interface Work {
    id: number;
    parcel: number;  // Parcel ID
    main_task: string;
    sub_task?: string | null;
    date: string; // Format: YYYY-MM-DD
  }
  
  export interface WorkResponse {
    results: Work[];
    next: string | null;
    previous: string | null;
    count: number;
  }

  // Function to get all works for a specific parcel
export const getWorksByParcel = async (parcelId: number, page: number = 1): Promise<WorkResponse> => {
  const response: AxiosResponse<WorkResponse> = await apiClient.get(`/works/parcel/${parcelId}/`, {
    params: { page }
  });
  return response.data;
};

// Función para obtener todos los trabajos
export const getAllWorks = async (date?: number): Promise<WorkResponse> => {
  const params: any = {};
  if (date) {
    const formattedDate = new Date(date).toISOString().split('T')[0];
    params.date = formattedDate; // Formatea la fecha como YYYY-MM-DD
  }

  const response: AxiosResponse<WorkResponse> = await apiClient.get('/works/works/', { params });
  return response.data;
};
// Función para obtener trabajos de una parcela específica
export const getWorksByParcelId = async (parcelId: number, date?: number): Promise<WorkResponse> => {
  const params: any = { parcel_id: parcelId };
  if (date) {
    const formattedDate = new Date(date).toISOString().split('T')[0];
    params.date = formattedDate; // Formatea la fecha como YYYY-MM-DD
  }

  const response: AxiosResponse<WorkResponse> = await apiClient.get('/works/works/', { params });
  return response.data;
};


// Function to get a single work by its ID
export const getWorkById = async (workId: number): Promise<Work> => {
  const response: AxiosResponse<Work> = await apiClient.get(`/works/works/${workId}/`);
  return response.data;
};

// Function to create a new work
export const createWork = async (data: Omit<Work, 'id'>): Promise<AxiosResponse<Work>> => {
  const response = await apiClient.post('/works/works/', data);
  return response;
};

// Function to update an existing work
export const updateWork = async (workId: number, data: Partial<Work>): Promise<AxiosResponse<Work>> => {
  const response: AxiosResponse<Work> = await apiClient.put(`/works/works/${workId}/`, data);
  return response;
};

// Function to delete a work by its ID
export const deleteWork = async (workId: number): Promise<AxiosResponse<any>> => {
  const response = await apiClient.delete(`/works/works/${workId}/`);
  return response;
};

// Función para obtener el último trabajo del usuario
export const getLastWork = async (): Promise<Work | null> => {
  try {
    const response: AxiosResponse<Work> = await apiClient.get('/works/works/last/');
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      // Si no se encuentra ningún trabajo, devolvemos null
      return null;
    }
    // Si es otro tipo de error, lo lanzamos para que se maneje en el componente
    throw error;
  }
};

///// CROPS ///

export interface CropResponse {
  results: Crop[];
  next: string | null;
  previous: string | null;
  count: number;
}

// Obtener todos los cultivos
export const getCrops = async (page: number = 1, parcelId?: number): Promise<CropResponse> => {
  const params: any = { page };
  if (parcelId) {
    params.parcel_id = parcelId;
  }
  const response: AxiosResponse<CropResponse> = await apiClient.get('/crops/', { params });
  return response.data;
};

// Obtener un cultivo específico por ID
export const getCropById = async (cropId: number): Promise<Crop> => {
  const response: AxiosResponse<Crop> = await apiClient.get(`/crops/${cropId}/`);
  return response.data;
};

// Crear un nuevo cultivo
export const createCrop = async (data: Omit<Crop, 'id'>): Promise<AxiosResponse<Crop>> => {
  const response = await apiClient.post('/crops/', data);
  return response;
};

// Actualizar un cultivo existente
export const updateCrop = async (cropId: number, data: Partial<Crop>): Promise<AxiosResponse<Crop>> => {
  const response: AxiosResponse<Crop> = await apiClient.put(`/crops/${cropId}/`, data);
  return response;
};

// Eliminar un cultivo
export const deleteCrop = async (cropId: number): Promise<AxiosResponse<any>> => {
  const response = await apiClient.delete(`/crops/${cropId}/`);
  return response;
};

// ... resto del código ...

// CUADERNO DE CAMPO
export const generateWorkfieldPDF = async (): Promise<Blob> => {
  const response: AxiosResponse<Blob> = await apiClient.get('/workfield/', {
    responseType: 'blob',
  });
  return response.data;
};



// ... resto del código ...

// Interfaces para Favourites Crops
export interface FavouriteCrop {
  id: string;
  name: string;
}

export interface FavouriteCropsResponse {
  crops: FavouriteCrop[];
}

// Obtener cultivos favoritos
export const getFavouriteCrops = async (): Promise<FavouriteCropsResponse> => {
  const response: AxiosResponse<FavouriteCropsResponse> = await apiClient.get('/favourites/crops/');
  return response.data;
};

// Añadir un nuevo cultivo favorito
export const addFavouriteCrop = async (cropName: string): Promise<AxiosResponse<any>> => {
  const response = await apiClient.post('/favourites/crops/', { crop_name: cropName });
  return response;
};

// Eliminar un cultivo favorito
export const deleteFavouriteCrop = async (cropName: string): Promise<AxiosResponse<any>> => {
  const response = await apiClient.delete('/favourites/crops/', { data: { crop_name: cropName } });
  return response;
};


// Interfaces para Maquinaria
export interface Machinery {
  id: number;
  roma_number: string;
  type: string;
  brand: string;
  model?: string;
  ownership: 'OWN' | 'RENTED';
  date_of_purchase?: string;
  date_of_revision?: string;
}
export interface MachineryResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Machinery[];
}

// Obtener toda la maquinaria
export const getMachinery = async (page: number = 1): Promise<MachineryResponse> => {
  const response: AxiosResponse<MachineryResponse> = await apiClient.get('/favourites/machinery/', {
    params: { page }
  });
  return response.data;
};

// Obtener una maquinaria específica por ID
export const getMachineryById = async (id: number): Promise<Machinery> => {
  const response: AxiosResponse<Machinery> = await apiClient.get(`/favourites/machinery/${id}/`);
  return response.data;
};

// Crear una nueva maquinaria
export const createMachinery = async (data: Omit<Machinery, 'id'>): Promise<Machinery> => {
  const response: AxiosResponse<Machinery> = await apiClient.post('/favourites/machinery/', data);
  return response.data;
};

// Actualizar una maquinaria existente
export const updateMachinery = async (id: number, data: Partial<Machinery>): Promise<Machinery> => {
  const response: AxiosResponse<Machinery> = await apiClient.put(`/favourites/machinery/${id}/`, data);
  return response.data;
};

// Eliminar una maquinaria
export const deleteMachinery = async (id: number): Promise<void> => {
  await apiClient.delete(`/favourites/machinery/${id}/`);
};

export interface Pesticide {
  id: number;
  name: string;
  registration_number: string;
  formulado: string;
  available_quantity?: number;

}

export interface PesticideResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Pesticide[];
}

// Obtener todos los pesticidas favoritos
export const getPesticides = async (page: number = 1): Promise<PesticideResponse> => {
  const response: AxiosResponse<PesticideResponse> = await apiClient.get('/favourites/pesticides/', {
    params: { page }
  });
  return response.data;
};

// Crear un nuevo pesticida favorito
export const createPesticide = async (data: Omit<Pesticide, 'id'>): Promise<Pesticide> => {
  const response: AxiosResponse<Pesticide> = await apiClient.post('/favourites/pesticides/', data);
  return response.data;
};

// // Actualizar un pesticida favorito existente
// export const updatePesticide = async (id: number, data: Partial<Pesticide>): Promise<Pesticide> => {
//   const response: AxiosResponse<Pesticide> = await apiClient.put(`/favourites/pesticides/${id}/`, data);
//   return response.data;
// };

// Eliminar un pesticida favorito
export const deletePesticide = async (id: number): Promise<void> => {
  await apiClient.delete(`/favourites/pesticides/${id}/`);
};

export interface Fertilizer {
  id: number;
  code: string;
  type: string;
  commercial_name: string;
  manufacturer: string;
  registration_date: string;
  available_quantity?: number;
}

export interface FertilizerResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: Fertilizer[];
}

// Obtener todos los fertilizantes favoritos
export const getFertilizers = async (page: number = 1): Promise<FertilizerResponse> => {
  const response: AxiosResponse<FertilizerResponse> = await apiClient.get('/favourites/fertilizers/', {
    params: { page }
  });
  return response.data;
};

// Obtener un fertilizante específico por ID
export const getFertilizerById = async (id: number): Promise<Fertilizer> => {
  const response: AxiosResponse<Fertilizer> = await apiClient.get(`/favourites/fertilizers/${id}/`);
  return response.data;
};

// Crear un nuevo fertilizante favorito
export const createFertilizer = async (data: Omit<Fertilizer, 'id'>): Promise<Fertilizer> => {
  const response: AxiosResponse<Fertilizer> = await apiClient.post('/favourites/fertilizers/', data);
  return response.data;
};

// Actualizar un fertilizante favorito existente
export const updateFertilizer = async (id: number, data: Partial<Fertilizer>): Promise<Fertilizer> => {
  const response: AxiosResponse<Fertilizer> = await apiClient.put(`/favourites/fertilizers/${id}/`, data);
  return response.data;
};

// Eliminar un fertilizante favorito
export const deleteFertilizer = async (id: number): Promise<void> => {
  await apiClient.delete(`/favourites/fertilizers/${id}/`);
};

// ... código existente ...

// Interfaces para InfoFarmer
export interface InfoFarmer {
  id?: number;
  full_name_or_company?: string;
  nif?: string;
  national_farm_registry?: string;
  regional_farm_registry?: string;
  address?: string;
  city?: string;
  postal_code?: string;
  province?: string;
  phone?: string;
  email?: string;
}


export interface InfoFarmerResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: InfoFarmer[];
}


export const getFarmerInfo = async (): Promise<InfoFarmer | null> => {
  const response: AxiosResponse<InfoFarmerResponse> = await apiClient.get('/farmers/info/');
  return response.data.results[0] || null;
};

// Crear información del agricultor
export const createFarmerInfo = async (data: Omit<InfoFarmer, 'id'>): Promise<InfoFarmer> => {
  const response: AxiosResponse<InfoFarmer> = await apiClient.post('/farmers/info/', data);
  return response.data;
};

// Actualizar información del agricultor
export const updateFarmerInfo = async (id: number, data: Partial<InfoFarmer>): Promise<InfoFarmer> => {
  const response: AxiosResponse<InfoFarmer> = await apiClient.put(`/farmers/info/${id}/`, data);
  return response.data;
};

// Eliminar información del agricultor
export const deleteFarmerInfo = async (id: number): Promise<void> => {
  await apiClient.delete(`/farmers/info/${id}/`);
};

// ... resto del código existente ...


export interface PesticideWork {
  id: number;
  parcel: number;
  start_date: string;
  end_date: string;
  surface_area: number;
  timing: 'pre_siembra' | 'pre_emergencia' | 'post_emergencia';
  pesticide: number;
  pest_or_disease: string;
  quantity_used: number;
  machinery: number;
}

export interface PesticideWorkResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: PesticideWork[];
}

// Obtener todos los trabajos con pesticidas
export const getPesticideWorks = async (page: number = 1): Promise<PesticideWorkResponse> => {
  const response: AxiosResponse<PesticideWorkResponse> = await apiClient.get('works/pesticide-works/', {
    params: { page }
  });
  return response.data;
};

// Obtener un trabajo con pesticida específico por ID
export const getPesticideWorkById = async (id: number): Promise<PesticideWork> => {
  const response: AxiosResponse<PesticideWork> = await apiClient.get(`works/pesticide-works/${id}/`);
  return response.data;
};

// Crear un nuevo trabajo con pesticida
export const createPesticideWork = async (data: Omit<PesticideWork, 'id'>): Promise<PesticideWork> => {
  const response: AxiosResponse<PesticideWork> = await apiClient.post('works/pesticide-works/', data);
  return response.data;
};

// Actualizar un trabajo con pesticida existente
export const updatePesticideWork = async (id: number, data: Partial<PesticideWork>): Promise<PesticideWork> => {
  const response: AxiosResponse<PesticideWork> = await apiClient.put(`works/pesticide-works/${id}/`, data);
  return response.data;
};

// Eliminar un trabajo con pesticida
export const deletePesticideWork = async (id: number): Promise<void> => {
  await apiClient.delete(`works/pesticide-works/${id}/`);
};
