import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Autocomplete, IconButton, Typography, Box, Grid, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createPesticideWork, getParcels, Parcel, getPesticides, getMachinery, Pesticide, Machinery } from '../../sdk';
import { getParcelData, MunicipioInfo } from '../parcel/ParcelData';

type TimingOption = "pre_siembra" | "pre_emergencia" | "post_emergencia";


interface AddPesticideWorkModalProps {
  open: boolean;
  onClose: (success?: boolean, message?: string) => void;
  parcel?: Parcel | null;
}

const AddPesticideWork: React.FC<AddPesticideWorkModalProps> = ({ open, onClose, parcel: initialParcel }) => {
  const [formData, setFormData] = useState({
    parcel: initialParcel || null,
    start_date: new Date().toISOString().split('T')[0],
    end_date: new Date().toISOString().split('T')[0],
    surface_area: initialParcel ? Number((initialParcel.area / 10000).toFixed(2)) : 0,
    timing: '' as TimingOption,
    pesticide: null as Pesticide | null,
    pest_or_disease: '',
    quantity_used: 0,
    machinery: null as Machinery | null,
  });
  const [parcels, setParcels] = useState<Parcel[]>([]);
  const [pesticides, setPesticides] = useState<Pesticide[]>([]);
  const [machineries, setMachineries] = useState<Machinery[]>([]);
  const [parcelData, setParcelData] = useState<MunicipioInfo | null>(null);
  const [errors, setErrors] = useState<{[key: string]: boolean}>({});

  useEffect(() => {
    if (open) {
      fetchParcels();
      fetchPesticides();
      fetchMachineries();
    }
  }, [open]);

  useEffect(() => {
    if (formData.parcel && formData.parcel.provincia && formData.parcel.municipio) {
      const data = getParcelData({
        provincia: formData.parcel.provincia,
        municipio: formData.parcel.municipio
      });
      setParcelData(data);
    } else {
      setParcelData(null);
    }
  }, [formData.parcel]);

  const fetchParcels = async () => {
    try {
      const response = await getParcels(1);
      setParcels(response.results);
    } catch (error) {
      console.error('Error fetching parcels:', error);
    }
  };

  const fetchPesticides = async () => {
    try {
      const response = await getPesticides();
      setPesticides(response.results);
    } catch (error) {
      console.error('Error fetching pesticides:', error);
    }
  };

  const fetchMachineries = async () => {
    try {
      const response = await getMachinery();
      setMachineries(response.results);
    } catch (error) {
      console.error('Error fetching machineries:', error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: false }));
  };

  const handleSurfaceAreaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    setFormData(prev => ({ 
      ...prev, 
      surface_area: isNaN(value) ? 0 : Number(value.toFixed(2))
    }));
    setErrors(prev => ({ ...prev, surface_area: false }));
  };

  const validateForm = () => {
    const newErrors: {[key: string]: boolean} = {};
    if (!formData.parcel) newErrors.parcel = true;
    if (!formData.start_date) newErrors.start_date = true;
    if (!formData.end_date) newErrors.end_date = true;
    if (formData.surface_area <= 0) newErrors.surface_area = true;
    if (!formData.timing) newErrors.timing = true;
    if (!formData.pesticide) newErrors.pesticide = true;
    if (!formData.pest_or_disease) newErrors.pest_or_disease = true;
    if (formData.quantity_used <= 0) newErrors.quantity_used = true;
    if (!formData.machinery) newErrors.machinery = true;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const pesticideWorkData = {
          ...formData,
          parcel: formData.parcel?.id ?? 0,
          pesticide: formData.pesticide?.id ?? 0,
          machinery: formData.machinery?.id ?? 0,
        };
        await createPesticideWork(pesticideWorkData);
        onClose(true, "Trabajo de pesticida añadido correctamente");
      } catch (error) {
        console.error('Error al crear el trabajo de pesticida:', error);
        onClose(false, "Error al crear el trabajo de pesticida");
      }
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="md" fullWidth>
      <DialogTitle>
        Añadir trabajo de pesticida
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              options={parcels}
              getOptionLabel={(option) => `${option.name} (${option.provincia}, ${option.municipio})`}
              value={formData.parcel}
              onChange={(_, newValue) => {
                setFormData(prev => ({
                  ...prev,
                  parcel: newValue,
                  surface_area: newValue ? Number((newValue.area / 10000).toFixed(2)) : 0
                }));
                setErrors(prev => ({ ...prev, parcel: false }));
              }}
              renderInput={(params) => <TextField {...params} label="Parcela" required error={errors.parcel} />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Fecha de inicio"
              type="date"
              name="start_date"
              value={formData.start_date}
              onChange={handleInputChange}
              required
              error={errors.start_date}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Fecha de fin"
              type="date"
              name="end_date"
              value={formData.end_date}
              onChange={handleInputChange}
              required
              error={errors.end_date}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Superficie (hectáreas)"
              type="number"
              value={formData.surface_area}
              onChange={handleSurfaceAreaChange}
              inputProps={{ step: "0.01" }}
              required
              error={errors.surface_area}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={machineries}
              getOptionLabel={(option: Machinery) => `${option.brand} ${option.model || ''} ${option.type} (${option.roma_number})`}
              value={formData.machinery}
              onChange={(_, newValue) => {
                setFormData(prev => ({ ...prev, machinery: newValue }));
                setErrors(prev => ({ ...prev, machinery: false }));
              }}
              renderInput={(params) => <TextField {...params} label="Maquinaria" required error={errors.machinery} />}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              label="Observaciones"
              name="timing"
              value={formData.timing}
              onChange={handleInputChange}
              required
              error={errors.timing}
            >
              <MenuItem value="pre_siembra">Pre-siembra</MenuItem>
              <MenuItem value="pre_emergencia">Pre-emergencia</MenuItem>
              <MenuItem value="post_emergencia">Post-emergencia</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={pesticides}
              getOptionLabel={(option) => option.name}
              value={formData.pesticide}
              onChange={(_, newValue) => {
                setFormData(prev => ({ ...prev, pesticide: newValue }));
                setErrors(prev => ({ ...prev, pesticide: false }));
              }}
              renderInput={(params) => <TextField {...params} label="Pesticida" required error={errors.pesticide} />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Plaga o enfermedad"
              name="pest_or_disease"
              value={formData.pest_or_disease}
              onChange={handleInputChange}
              required
              error={errors.pest_or_disease}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Cantidad usada (kg)"
              type="number"
              name="quantity_used"
              value={formData.quantity_used}
              onChange={handleInputChange}
              required
              error={errors.quantity_used}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancelar</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPesticideWork;