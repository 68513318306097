import React, { useState, useEffect } from 'react';
import { Typography, Grid, Fab, Card, CardContent, CardActions, Tooltip, IconButton, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getMachinery, Machinery, createMachinery, updateMachinery, deleteMachinery } from '../../sdk';
import AddMachinery from './AddMachinery';
import { styled } from '@mui/material/styles';

// Función para obtener el emoji según el tipo de maquinaria
const getMachineryEmoji = (type: string) => {
  const emojiMap: { [key: string]: string } = {
    "TRACTORES": "🚜",
    "TRACTOCARROS": "🚛",
    "MAQUINARIA ARRASTRADA O SUSPENDIDA": "🔗🚜",
    "MAQUINARIA AUTOMOTRIZ": "🚜",
    "MAQUINARIA DE PREPARACIÓN Y TRABAJO DEL SUELO": "🌱",
    "MAQUINARIA DE RECOLECCIÓN": "🌾",
    "MOTOCULTORES Y MOTOMÁQUINAS": "🚜",
    "EQUIPOS DE CARGA": "🏗️",
    "EQUIPOS DE RECOLECCIÓN": "🌾",
    "EQUIPOS DE TRATAMIENTOS": "💨",
    "EQUIPOS PARA APORTE DE FERTILIZANTES Y AGUA": "💦",
    "EQUIPOS PARA SIEMBRA Y PLANTACIÓN": "🌱",
    "REMOLQUES": "🚛",
    "OTROS": "🔧"
    // Añade más tipos y emojis según sea necesario
  };
  return emojiMap[type] || '🔧'; // Emoji por defecto si no se encuentra el tipo
};

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius * 2,
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 8px rgba(0,0,0,0.15)',
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1.5, 2),
  borderTopLeftRadius: theme.shape.borderRadius * 2,
  borderTopRightRadius: theme.shape.borderRadius * 2,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const MachineryComponent: React.FC = () => {
  const [machinery, setMachinery] = useState<Machinery[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentMachinery, setCurrentMachinery] = useState<Machinery | null>(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [machineToDelete, setMachineToDelete] = useState<number | null>(null);

  const fetchMachinery = async () => {
    try {
      const response = await getMachinery();
      setMachinery(response.results);
    } catch (error) {
      console.error('Error al obtener la maquinaria:', error);
    }
  };

  useEffect(() => {
    fetchMachinery();
  }, []);

  const handleOpenDialog = (machine?: Machinery) => {
    setCurrentMachinery(machine || null);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setCurrentMachinery(null);
  };

  const handleSubmit = async (formData: Omit<Machinery, 'id'>) => {
    try {
      if (currentMachinery) {
        await updateMachinery(currentMachinery.id, formData);
      } else {
        await createMachinery(formData);
      }
      fetchMachinery();
      handleCloseDialog();
    } catch (error) {
      console.error('Error al guardar la maquinaria:', error);
    }
  };

  const handleDeleteConfirm = (id: number) => {
    setMachineToDelete(id);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setMachineToDelete(null);
  };

  const handleDeleteConfirmed = async () => {
    if (machineToDelete) {
      try {
        await deleteMachinery(machineToDelete);
        fetchMachinery();
      } catch (error) {
        console.error('Error al eliminar la maquinaria:', error);
      }
    }
    setDeleteConfirmOpen(false);
    setMachineToDelete(null);
  };

  const handleDelete = async (id: number) => {
    handleDeleteConfirm(id);
  };

  return (
    <>
      <AddMachinery
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onSubmit={handleSubmit}
        currentMachinery={currentMachinery}
      />
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
        <Grid item>
          <Typography 
            variant="h5" 
            component="h2" 
            sx={{ 
              fontWeight: 'bold', 
              color: 'primary.main',
              borderBottom: '2px solid',
              borderColor: 'primary.main',
              paddingBottom: 1
            }}
          >
            Maquinaria
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip title="Añadir Maquinaria">
            <Fab color="primary" size="small" onClick={() => handleOpenDialog()} sx={{ bgcolor: 'success.light' }}>
              <AddIcon />
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {machinery.map((machine) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={machine.id}>
            <StyledCard>
              <CardHeader>
                <Typography 
                  variant="subtitle1" 
                  component="div" 
                  fontWeight="bold"
                  sx={{
                    flexGrow: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {`${getMachineryEmoji(machine.type)} ${machine.brand} - ${machine.roma_number}`}
                </Typography>
                <Chip
                  label={machine.ownership === 'OWN' ? 'Propia' : 'Alquilada'}
                  size="small"
                  color={machine.ownership === 'OWN' ? 'success' : 'info'}
                  sx={{ fontWeight: 'bold', fontSize: '0.65rem', ml: 1 }}
                />
              </CardHeader>
              <CardContent sx={{ flexGrow: 1, p: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                  <Typography 
                    variant="body2" 
                    component="div" 
                    fontWeight="bold"
                    color="text.secondary"
                  >
                    {machine.type}
                  </Typography>
                </Box>
                <Typography variant="body2" sx={{ mb: 0.5 }}>
                  <strong>Modelo:</strong> {machine.model || 'N/A'}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end', p: 1, pt: 0 }}>
                <Tooltip title="Editar">
                  <IconButton size="small" onClick={() => handleOpenDialog(machine)} color="primary">
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Eliminar">
                  <IconButton size="small" onClick={() => handleDeleteConfirm(machine.id)} color="error">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </CardActions>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 2, p: 2, borderRadius: 1, bgcolor: 'background.paper', border: '1px solid', borderColor: 'divider' }}>
        <InfoOutlinedIcon sx={{ color: 'text.secondary', mr: 2, mt: 0.5 }} />
        <Typography variant="body2" color="text.secondary">
          Aquí puedes gestionar tu maquinaria agrícola. Esta información te será útil para llevar un registro de tus equipos y planificar su mantenimiento.
        </Typography>
      </Box>
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Confirmar eliminación?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estás seguro de que quieres eliminar esta maquinaria? Esta acción no se puede deshacer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirmed} color="error" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MachineryComponent;