import React, { useState, useEffect } from 'react';
import { InfoFarmer, getFarmerInfo, updateFarmerInfo } from '../../sdk';
import { 
  Container, 
  Typography, 
  TextField, 
  Button, 
  Grid, 
  Box,
  Snackbar,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import Alert from '@mui/material/Alert';
import { SelectChangeEvent } from '@mui/material/Select';

const fieldLabels: { [key: string]: string } = {
  full_name_or_company: 'Nombre completo o empresa',
  nif: 'NIF',
  national_farm_registry: 'Registro nacional de explotación',
  regional_farm_registry: 'Registro regional de explotación',
  address: 'Dirección',
  city: 'Ciudad',
  postal_code: 'Código postal',
  province: 'Provincia',
  phone: 'Teléfono',
  email: 'Correo electrónico'
};

const provinces = [
  'Álava', 'Albacete', 'Alicante', 'Almería', 'Asturias', 'Ávila',
  'Badajoz', 'Barcelona', 'Burgos', 'Cáceres', 'Cádiz', 'Cantabria',
  'Castellón', 'Ciudad Real', 'Córdoba', 'Cuenca', 'Girona', 'Granada',
  'Guadalajara', 'Guipúzcoa', 'Huelva', 'Huesca', 'Illes Balears',
  'Jaén', 'La Coruña', 'La Rioja', 'Las Palmas', 'León', 'Lérida',
  'Lugo', 'Madrid', 'Málaga', 'Murcia', 'Navarra', 'Ourense', 'Palencia',
  'Pontevedra', 'Salamanca', 'Santa Cruz de Tenerife', 'Segovia',
  'Sevilla', 'Soria', 'Tarragona', 'Teruel', 'Toledo', 'Valencia',
  'Valladolid', 'Vizcaya', 'Zamora', 'Zaragoza', 'Ceuta', 'Melilla'
];

function MyData() {
  const [infoFarmer, setInfoFarmer] = useState<InfoFarmer | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getFarmerInfo();
        setInfoFarmer(data);
      } catch (error) {
        console.error('Error al cargar los datos del agricultor:', error);
        setSnackbarMessage('Error al cargar los datos');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent) => {
    const { name, value } = e.target;
    setInfoFarmer(prev => prev ? { ...prev, [name]: value } : null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (infoFarmer && infoFarmer.id) {
      try {
        const updatedInfo = await updateFarmerInfo(infoFarmer.id, infoFarmer);
        setInfoFarmer(updatedInfo);
        setSnackbarMessage('Datos guardados correctamente');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
      } catch (error) {
        console.error('Error al guardar los datos:', error);
        setSnackbarMessage('Error al guardar los datos');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    }
  };

  if (isLoading) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <Typography>Cargando datos...</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
        <Grid item>
          <Typography 
            variant="h5" 
            component="h2" 
            sx={{ 
              fontWeight: 'bold', 
              color: 'primary.main',
              borderBottom: '2px solid',
              borderColor: 'primary.main',
              paddingBottom: 1
            }}
          >
            Mis Datos
          </Typography>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {Object.entries(fieldLabels).map(([key, label]) => {
            const value = infoFarmer?.[key as keyof InfoFarmer] || '';
            const isEmpty = value === '';

            if (key === 'province') {
              return (
                <Grid item xs={12} sm={6} key={key}>
                  <FormControl fullWidth error={isEmpty}>
                    <InputLabel id={`${key}-label`}>{label}</InputLabel>
                    <Select
                      labelId={`${key}-label`}
                      id={key}
                      name={key}
                      value={value as string}
                      onChange={(e) => handleChange(e as SelectChangeEvent)}
                      label={label}
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: isEmpty ? 'warning.main' : 'inherit',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: isEmpty ? 'warning.main' : 'inherit',
                        },
                      }}
                    >
                      {provinces.map((province) => (
                        <MenuItem key={province} value={province}>
                          {province}
                        </MenuItem>
                      ))}
                    </Select>
                    {isEmpty && (
                      <Typography variant="caption" color="error">
                        Este campo es requerido
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              );
            }

            return (
              <Grid item xs={12} sm={6} key={key}>
                <TextField
                  fullWidth
                  label={label}
                  name={key}
                  value={value}
                  onChange={handleChange as React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>}
                  variant="outlined"
                  error={isEmpty}
                  helperText={isEmpty ? "Este campo es requerido" : ""}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: isEmpty ? 'warning.main' : 'inherit',
                      },
                      '&:hover fieldset': {
                        borderColor: isEmpty ? 'warning.main' : 'inherit',
                      },
                    },
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            size="large"
          >
            Guardar cambios
          </Button>
        </Box>
      </form>
      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={4000} 
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{ top: '64px', left: '24px' }}
      >
        <Alert 
          onClose={() => setOpenSnackbar(false)} 
          severity={snackbarSeverity} 
          sx={{ width: '100%', maxWidth: '400px' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default MyData;