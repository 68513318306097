import React, { useRef, useEffect, useState, useCallback } from 'react';
import mapboxgl, { Map as MapboxMap, Marker, MapMouseEvent, MapTouchEvent } from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as turf from '@turf/turf';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { Parcel } from '../parcel/ParcelContext';
import { Autocomplete, TextField, Button, Box, Typography } from '@mui/material';
import Cookies from 'universal-cookie';
import { createParcel, getParcels, getDrawParcels, DrawParcelsParams } from '../../sdk';
import proj4 from 'proj4';
import { FeatureCollection, Feature, Geometry, GeoJsonProperties, Position, Polygon } from 'geojson';
import InfoParcel from './InfoParcel';
import InfoMyParcel from './InfoMyParcel';
import EditParcel from '../parcel/actions/EditParcel';
import AddWork from '../works/AddNormalWork';


proj4.defs("EPSG:3857", "+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs");
proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs");

const cookies = new Cookies();

const MapComponent: React.FC = () => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const mapboxToken = process.env.REACT_APP_MAPBOX_API || '';
  const [parcels, setParcels] = useState<Parcel[]>([]);
  const parcelsRef = useRef<Parcel[]>([]);
  const [drawnPolygon, setDrawnPolygon] = useState<any>(null);
  const [newParcelName, setNewParcelName] = useState<string>("");
  const [apiResponse, setApiResponse] = useState<string | null>(null);
  const [selectedParcel, setSelectedParcel] = useState<Parcel | null>(null);
  const mapRef = useRef<MapboxMap | null>(null);
  const drawRef = useRef<MapboxDraw | null>(null);
  const [userLocation, setUserLocation] = useState<[number, number] | null>(null);
  const [showInfoParcel, setShowInfoParcel] = useState(false);
  const [showInfoMyParcel, setShowInfoMyParcel] = useState(false);
  const [parcelProperties, setParcelProperties] = useState<any>(null);
  const [selectedMyParcel, setSelectedMyParcel] = useState<Parcel | null>(null);
  const [centerMarker, setCenterMarker] = useState<{ lng: number; lat: number } | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const dragStartPos = useRef<[number, number] | null>(null);
  const DRAG_THRESHOLD = 5; // píxeles
  const [isEditParcelModalOpen, setIsEditParcelModalOpen] = useState(false);
  const [isAddWorkModalOpen, setIsAddWorkModalOpen] = useState(false);

  // Añade esta nueva función
  const handleWorkAdded = useCallback(() => {
    // Aquí puedes realizar cualquier acción necesaria después de añadir un trabajo
    // Por ejemplo, refrescar los datos de la parcela
    console.log(`Trabajo añadido: `); // Para depuración

    refreshParcels();
    setIsAddWorkModalOpen(false);
  }, []);

  const parcelDataCache: { [key: string]: any } = {};

  interface TileCoords {
    x: number;
    y: number;
  }

  function latLonToTile(lat: number, lon: number, zoom: number): TileCoords {
    const latRad = lat * Math.PI / 180;
    const n = Math.pow(2, zoom);
    const tileX = Math.floor((lon + 180) / 360 * n);
    const originalTileY = Math.floor((1 - Math.log(Math.tan(latRad) + 1 / Math.cos(latRad)) / Math.PI) / 2 * n);
    const tileY = n - 1 - originalTileY;
    return { x: tileX, y: tileY };
  }

  proj4.defs('EPSG:3857', '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs');
  proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');

  const transformCoords = (geojson: FeatureCollection<Geometry>, projection: string): FeatureCollection<Geometry> => {
    const sourceProjection = 'EPSG:3857';
    const result: FeatureCollection<Geometry> = {
      ...geojson,
      features: geojson.features.map((feature: Feature<Geometry>) => {
        const { geometry } = feature;
        let transformedCoordinates: any;

        switch (geometry.type) {
          case 'Point':
            transformedCoordinates = proj4(sourceProjection, projection, geometry.coordinates as Position);
            break;
          case 'MultiPoint':
          case 'LineString':
            transformedCoordinates = (geometry.coordinates as Position[]).map((coord) =>
              proj4(sourceProjection, projection, coord)
            );
            break;
          case 'Polygon':
            transformedCoordinates = (geometry.coordinates as Position[][]).map((ring) =>
              ring.map((coord) => proj4(sourceProjection, projection, coord))
            );
            break;
          case 'MultiLineString':
          case 'MultiPolygon':
            transformedCoordinates = (geometry.coordinates as Position[][][]).map((polygon) =>
              polygon.map((ring) =>
                ring.map((coord) => proj4(sourceProjection, projection, coord))
              )
            );
            break;
          default:
            throw new Error(`Unsupported geometry type: ${geometry.type}`);
        }

        return {
          ...feature,
          geometry: {
            ...geometry,
            coordinates: transformedCoordinates,
          },
        };
      }),
    };

    return result;
  };

  function transformGeometry(
    geometry: Geometry,
    sourceProjection: string,
    targetProjection: string
  ): Geometry {
    switch (geometry.type) {
      case 'Point':
        return {
          ...geometry,
          coordinates: proj4(sourceProjection, targetProjection, geometry.coordinates as Position)
        };
      case 'MultiPoint':
      case 'LineString':
        return {
          ...geometry,
          coordinates: (geometry.coordinates as Position[]).map(coord =>
            proj4(sourceProjection, targetProjection, coord))
        };
      case 'MultiLineString':
      case 'Polygon':
        return {
          ...geometry,
          coordinates: (geometry.coordinates as Position[][]).map(ring =>
            ring.map(coord => proj4(sourceProjection, targetProjection, coord))
          )
        };
      case 'MultiPolygon':
        return {
          ...geometry,
          coordinates: (geometry.coordinates as Position[][][]).map(polygon =>
            polygon.map(ring => ring.map(coord => proj4(sourceProjection, targetProjection, coord)))
          )
        };
      default:
        return geometry;
    }
  }

  const refreshParcels = useCallback(async () => {
    try {
      const updatedResponse = await getParcels();
      const updatedParcels = updatedResponse.results;
      setParcels(updatedParcels);
      parcelsRef.current = updatedParcels;

      // Añadir nuevas parcelas al mapa sin eliminar las capas existentes
      updatedParcels.forEach((parcel, index) => {
        const polygonId = `polygon-${index}`;

        // Verificar si ya existe la capa para esta parcela
        if (!mapRef.current?.getSource(polygonId)) {
          mapRef.current?.addSource(polygonId, {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {
                name: parcel.name,
                area: parcel.area,
                id: parcel.id
              },
              geometry: {
                type: 'Polygon',
                coordinates: [parcel.coordinates]
              }
            }
          });

          mapRef.current?.addLayer({
            id: polygonId,
            type: 'line',
            source: polygonId,
            layout: {},
            paint: {
              'line-color': '#0000ff',
              'line-width': 1
            }
          });

          mapRef.current?.addLayer({
            id: `${polygonId}-fill`,
            type: 'fill',
            source: polygonId,
            layout: {},
            paint: {
              'fill-color': '#50D050',
              'fill-opacity': 0.7
            }
          });
        }
      });
    } catch (error) {
      console.error('Error al obtener las parcelas actualizadas:', error);
    }
  }, []);

  class ZoomInControl implements mapboxgl.IControl {
    map: MapboxMap | undefined;
    container: HTMLElement | undefined;

    onAdd(map: MapboxMap): HTMLElement {
      this.map = map;
      this.container = document.createElement('div');
      this.container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
      this.container.innerHTML = '<button class="mapboxgl-ctrl-icon" type="button">+</button>';
      this.container.onclick = () => {
        map.zoomIn();
      };
      return this.container;
    }

    onRemove() {
      if (this.container && this.container.parentNode) {
        this.container.parentNode.removeChild(this.container);
      }
      this.map = undefined;
    }
  }

  class ZoomOutControl implements mapboxgl.IControl {
    map: MapboxMap | undefined;
    container: HTMLElement | undefined;

    onAdd(map: MapboxMap): HTMLElement {
      this.map = map;
      this.container = document.createElement('div');
      this.container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
      this.container.innerHTML = '<button class="mapboxgl-ctrl-icon" type="button">-</button>';
      this.container.onclick = () => {
        map.zoomOut();
      };
      return this.container;
    }

    onRemove() {
      if (this.container && this.container.parentNode) {
        this.container.parentNode.removeChild(this.container);
      }
      this.map = undefined;
    }
  }

  async function fetchParcelData(lat: number, lng: number, zoom: number) {
    console.log("Fetching parcel data for lat:", lat, "lng:", lng, "zoom:", zoom);
    const { x, y } = latLonToTile(lat, lng, 15);
    
    const tilesToFetch = [
      { x, y },
      { x: x + 1, y },
      { x, y: y + 1 }
    ];
  
    const allData: FeatureCollection<Geometry, GeoJsonProperties> = {
      type: "FeatureCollection",
      features: []
    };
  
    for (const tile of tilesToFetch) {
      const cacheKey = `parcel_data_${tile.x}_${tile.y}`;
      let tileData: any = null;
  
      // Intentar obtener datos de la caché local
      const cachedData = localStorage.getItem(cacheKey);
      if (cachedData) {
        try {
          tileData = JSON.parse(cachedData);
          console.log(`Using cached data for tile ${cacheKey}`);
        } catch (error) {
          console.error(`Error parsing cached data for tile ${cacheKey}:`, error);
        }
      }
  
      if (!tileData) {
        // Si no hay datos en caché, hacer la petición a la API
        const params: DrawParcelsParams = { x: tile.x, y: tile.y };
        try {
          const response = await getDrawParcels(params);
          if (response && response.data) {
            tileData = response.data;
            // Guardar los nuevos datos en la caché local
            localStorage.setItem(cacheKey, JSON.stringify(tileData));
            console.log(`Cached new data for tile ${cacheKey}`);
          } else {
            console.error(`No response data for tile ${cacheKey}`);
          }
        } catch (error: any) {
          console.error(`Error fetching parcel data for tile ${cacheKey}: ${error.message}`);
        }
      }
  
      if (tileData && tileData.features) {
        allData.features.push(...tileData.features);
      }
    }
  
    return allData;
      }
  // Inicializar parcels al inicio
  useEffect(() => {
    const initializeParcels = async () => {
      try {
        const response = await getParcels();
        setParcels(response.results);
        parcelsRef.current = response.results;
      } catch (error) {
        console.error("Error al cargar las parcelas iniciales:", error);
      }
    };

    initializeParcels();
  }, []);

  // Actualizar parcelsRef cuando parcels cambie
  useEffect(() => {
    parcelsRef.current = parcels;
  }, [parcels]);

  useEffect(() => {
    // INICIAMOS AQUI
    const initializeMap = () => {
      mapboxgl.accessToken = mapboxToken;
      const map = new mapboxgl.Map({
        container: mapContainer.current!,
        style: 'mapbox://styles/mapbox/satellite-streets-v11',
        attributionControl: false,
        center: [-5.465382, 41.680551],
        logoPosition: 'top-left',         // Deshabilita el logo de Mapbox
        zoom: 12
      });

      mapRef.current = map;
      // fetchParcelData(41.6805933697800, -5.465421274802765, 14);
      

      // Añadir el control de geocodificación
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        placeholder: 'Buscar localidad',
        countries: 'es', // Limitar la búsqueda a España
      });

      map.addControl(geocoder);

      // Ajustar el tamaño del control de geocodificación
      const geocoderContainer = document.querySelector('.mapboxgl-ctrl-geocoder') as HTMLElement;
      if (geocoderContainer) {
        geocoderContainer.style.minWidth = '200px';
        geocoderContainer.style.width = '50%';
        geocoderContainer.style.maxWidth = '300px';
        
        const input = geocoderContainer.querySelector('input') as HTMLInputElement;
        if (input) {
          input.style.height = '36px';
          input.style.fontSize = '14px';
          input.style.paddingLeft = '30px'; // Espacio para el icono de la lupa
        }

        const icon = geocoderContainer.querySelector('.mapboxgl-ctrl-geocoder--icon') as HTMLElement;
        if (icon) {
          icon.style.top = '8px';
          icon.style.left = '5px';
        }

        const suggestions = geocoderContainer.querySelector('.suggestions') as HTMLElement;
        if (suggestions) {
          suggestions.style.fontSize = '14px';
        }
      }

      // Asegurarse de que el contenedor del mapa tenga suficiente altura
      if (mapContainer.current) {
        mapContainer.current.style.minHeight = '400px';
      }

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      });

      map.on('load', async () => {
        try {
          // TODO Cambiar esto por la geolocalización
          await addParcelLayers(map, 41.680551, -5.465382, 15);
          drawPolygons(map, popup);

          map.addControl(new ZoomInControl(), 'top-right');
          map.addControl(new ZoomOutControl(), 'top-right');

          await refreshParcels();

          map.on('draw.create', (e) => {
            const polygon = e.features[0];
            setDrawnPolygon(polygon);
            map.getCanvas().style.cursor = '';
          });
        } catch (error) {
          console.error('Error al cargar y procesar el TIFF:', error);
        }
      });

      map.on('movestart', () => {
        setIsDragging(true);
      });

      map.on('moveend', async () => {
        setIsDragging(false);
        const zoom = map.getZoom();
        if (zoom >= 13) {
          const center = map.getCenter();
          await addParcelLayers(map, center.lat, center.lng, zoom);
        }
      });

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation([longitude, latitude]);

          new mapboxgl.Marker({ color: 'red' })
            .setLngLat([longitude, latitude])
                    }, (error) => {
          console.error("Error obteniendo la ubicación:", error);
        });
            }

      return () => map.remove();
    };

    
    if (!mapRef.current) {
      initializeMap(); // Solo inicializa el mapa si no ha sido inicializado
    }
  }, [mapboxToken]);

  const handleSave = (data: { text: string; selectedOption: string }) => {
    refreshParcels();
    setShowInfoParcel(false)

    // Llamada a refreshParcels para obtener las parc    setShowInfoParcel(false);
  };

  const drawPolygons = (map: MapboxMap, popup: mapboxgl.Popup) => {
    parcels.forEach((parcel, index) => {
      const polygonId = `polygon-${index}`;

      if (!map.getSource(polygonId)) {
        map.addSource(polygonId, {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {
              name: parcel.name,
              area: parcel.area,
              id: parcel.id
            },
            geometry: {
              type: 'Polygon',
              coordinates: [parcel.coordinates]
            }
          }
        });

        map.addLayer({
          id: polygonId,
          type: 'line',
          source: polygonId,
          layout: {},
          paint: {
            'line-color': '#0000ff',
            'line-width': 1
          }
        });

        map.addLayer({
          id: `${polygonId}-fill`,
          type: 'fill',
          source: polygonId,
          layout: {},
          paint: {
            'fill-color': '#3d85c6',
            'fill-opacity': 1
          }
        });
      }

      interface ParcelProperties {
        name: string;
        area: number;
        id: number;
      }

      map.on('mousemove', `${polygonId}-fill`, (e) => {
        const features = map.queryRenderedFeatures(e.point, { layers: [`${polygonId}-fill`] });
        if (features.length > 0) {
          const feature = features[0];
          const coordinates = e.lngLat;
          const { name, area } = feature.properties as ParcelProperties;

          popup
            .setLngLat(coordinates)
            .setHTML(`<strong>${name}</strong><br/>Area: ${area} ha`)
            .addTo(map);
        }
      });

      map.on('mouseleave', `${polygonId}-fill`, () => {
        popup.remove();
      });

      map.on('click', `${polygonId}-fill`, (e) => {
        const features = map.queryRenderedFeatures(e.point, { layers: [`${polygonId}-fill`] });
        console.log("HAGO CLICK")
        if (features.length > 0 && features[0].properties) {
          const feature = features[0];
          const properties = feature.properties as { id: number, name: string, area: number };
          
          // Buscar la parcela en parcels
          const clickedParcel = parcels.find(p => p.id === properties.id);
          
          if (clickedParcel) {
            // Si se encuentra en parcels, usar esa
            setSelectedParcel(clickedParcel);
          } else {
            // Si no se encuentra en parcels, buscar en la lista original
            setSelectedParcel(parcels.find(p => p.id === properties.id) || null);
          }
          
          // Actualizar el marcador en el centro de la parcela
          if (feature.geometry.type === 'Polygon') {
            addCenterMarker(feature.geometry.coordinates[0]);
          }
          
          // Actualizar la visualización de la información
          setShowInfoMyParcel(!!clickedParcel);
          setShowInfoParcel(!clickedParcel);
        }
      });
      console.log("HAGO CLICK 123")


    });
  };

  const handleCreateParcel = async () => {
    if (drawnPolygon) {
      const coordinates = drawnPolygon.geometry.coordinates[0];
      const area = turf.area(drawnPolygon) / 10000;
      const name = newParcelName;
      const agromonitoring_id = '1111';

      try {
        const token = cookies.get('token');
        const response = await createParcel(
          {
            coordinates,
            area,
            name,
            agromonitoring_id
          },
        );

        if (response.data.status === 200 || response.status === 201) {
          setApiResponse('Parcela creada con éxito');
          setDrawnPolygon(null);
          setNewParcelName("");

          const newParcel = {
            id: response.data.id,
            name: name,
            area: area,
            coordinates: coordinates
          };

          setParcels(prevParcels => [...prevParcels, newParcel]);

          const polygonId = `polygon-${parcels.length}`;

          mapRef.current?.addSource(polygonId, {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {
                name: newParcel.name,
                area: newParcel.area,
                id: newParcel.id
              },
              geometry: {
                type: 'Polygon',
                coordinates: [newParcel.coordinates]
              }
            }
          });

          mapRef.current?.addLayer({
            id: polygonId,
            type: 'line',
            source: polygonId,
            layout: {},
            paint: {
              'line-color': '#0000ff',
              'line-width': 1
            }
          });

          mapRef.current?.addLayer({
            id: `${polygonId}-fill`,
            type: 'fill',
            source: polygonId,
            layout: {},
            paint: {
              'fill-color': '#0b5394',
              'fill-opacity': 1
            }
          });
        }
      } catch (error: any) {
        setApiResponse('Error al crear la parcela');
      }
    }
  };

  const addCenterMarker = (coordinates: number[][]) => {
    if (mapRef.current && coordinates.length > 0 && coordinates[0].length >= 2) {
      // Eliminar el marcador anterior si existe
        const markers = document.querySelectorAll('.mapboxgl-marker');
        markers.forEach(marker => marker.remove());
      
  
      const bounds = coordinates.reduce((bounds, coord) => {
        return bounds.extend(coord as [number, number]);
      }, new mapboxgl.LngLatBounds(coordinates[0] as [number, number], coordinates[0] as [number, number]));
  
      const rawCenter = bounds.getCenter();
      
      // Redondear a 5 decimales
      const center = {
        lng: Number(rawCenter.lng.toFixed(5)),
        lat: Number(rawCenter.lat.toFixed(5))
      };
      
      
      new mapboxgl.Marker({ color: "#FF0000" })
        .setLngLat([center.lng, center.lat])
        .addTo(mapRef.current);
  
      // Aquí establecemos las coordenadas en el estado
      setCenterMarker(center);
    } else {
      console.log("No se pudo añadir el marcador. Map ref o coordenadas inválidas.");
    }
  };
  
  const flyToParcel = (parcel: Parcel) => {
    setShowInfoParcel(false);
    setShowInfoMyParcel(false)
    setSelectedMyParcel(parcel)
    setShowInfoMyParcel(true)

    if (mapRef.current && parcel.coordinates.length > 0) {
      const bounds = new mapboxgl.LngLatBounds();

      parcel.coordinates.forEach(coord => {
        if (coord.length === 2) {
          bounds.extend([coord[0], coord[1]] as [number, number]);
        }
      });

      mapRef.current.fitBounds(bounds, { padding: 20 });
      
      // Actualizar el marcador en el centro de la parcela
      addCenterMarker(parcel.coordinates);
    }
  };

  const handleEditParcel = () => {
    console.log("EDITAR")
    setIsEditParcelModalOpen(true);
  };

  const handleAddWork = () => {
    console.log("TRABAJAR")

    setIsAddWorkModalOpen(true);
  };


  const addParcelLayers = async (map: MapboxMap, lat: number, lng: number, zoom: number) => {
    const { x, y } = latLonToTile(lat, lng, 15);
    const cacheKey = `${x}-${y}`;

    if (map.getSource(`parcel-data-sigpac-${cacheKey}`)) {
      return;
    }

    try {
      console.log("GENERO LAS PARCELAS. LAS PRIMERAS")
      const data = await fetchParcelData(lat, lng, zoom);

      const parcelSourceId = `parcel-data-sigpac-${cacheKey}`;

      const transformedData = transformCoords(data, 'WGS84');

      map.addSource(parcelSourceId, {
        type: 'geojson',
        data: transformedData
      });

      map.addLayer({
        id: `${parcelSourceId}-fill`,
        type: 'fill',
        source: parcelSourceId,
        layout: {},
        paint: {
          'fill-color': '#ffe599',
          'fill-opacity': 0
        }
      });

      map.addLayer({
        id: `${parcelSourceId}-line`,
        type: 'line',
        source: parcelSourceId,
        layout: {},
        paint: {
          'line-color': '#000000',
          'line-width': 1
        }
      });

      const handleClickOrTap = (e: mapboxgl.MapMouseEvent | mapboxgl.MapTouchEvent) => {
        if (!isDragging) {
          const clickPoint = turf.point([e.lngLat.lng, e.lngLat.lat]);

          // Primero, buscar en parcelsRef.current
          const clickedSavedParcel = parcelsRef.current.find((parcel: Parcel) => {
            const polygon = turf.polygon([parcel.coordinates]);
            return turf.booleanPointInPolygon(clickPoint, polygon);
          });

          if (clickedSavedParcel) {
            // Si se encuentra una parcela en parcelsRef.current
            setSelectedParcel(clickedSavedParcel);
            setSelectedMyParcel(clickedSavedParcel);
            setShowInfoParcel(false);
            setShowInfoMyParcel(true);
            addCenterMarker(clickedSavedParcel.coordinates);
          } else {
            // Buscar en todas las capas, incluyendo las de SIGPAC
            const allLayers = map.getStyle()?.layers
              ?.filter(layer => layer.id.includes('-fill'))
              ?.map(layer => layer.id) ?? [];
            console.log(allLayers)
            const features = map.queryRenderedFeatures(e.point, { layers: allLayers });

            if (features && features.length > 0 && features[0].properties) {
              let feature = features[0].geometry as any;
              features[0].properties.coordinates = feature.coordinates[0];
              setParcelProperties(features[0].properties);
              // Comprobar si la parcela clickeada es una de las tuyas
              const clickedParcel = parcels.find(p => p.id === features[0].properties?.id);
              
              if (clickedParcel) {
                setSelectedParcel(clickedParcel);
                setSelectedMyParcel(clickedParcel);
                setShowInfoParcel(false);
                setShowInfoMyParcel(true);
              } else {
                setSelectedParcel(null);
                setSelectedMyParcel(null);
                setShowInfoParcel(true);
                setShowInfoMyParcel(false);
              }

              if (feature.type === 'Polygon') {
                addCenterMarker(feature.coordinates[0]);
              }
            } else {
              // Si no se encuentra en ninguna parte, limpiar la selección y remover el marcador
              setSelectedParcel(null);
              setSelectedMyParcel(null);
              setShowInfoParcel(false);
              setShowInfoMyParcel(false);
              removeCenterMarker();
            }
          }
        }
        setIsDragging(false);
        dragStartPos.current = null;
      };

      // Añade esta nueva función para remover el marcador central
      const removeCenterMarker = () => {
        const markers = document.querySelectorAll('.mapboxgl-marker');
        markers.forEach(marker => marker.remove());
      };

      map.on('click', handleClickOrTap);

      map.on('mousedown', (e) => {
        dragStartPos.current = [e.point.x, e.point.y];
      });

      map.on('touchstart', (e) => {
        if (e.points.length > 0) {
          dragStartPos.current = [e.points[0].x, e.points[0].y];
        }
      });

      map.on('mousemove', (e) => {
        if (dragStartPos.current) {
          const [startX, startY] = dragStartPos.current;
          const dx = e.point.x - startX;
          const dy = e.point.y - startY;
          if (Math.sqrt(dx * dx + dy * dy) > DRAG_THRESHOLD) {
            setIsDragging(true);
          }
        }
      });

      map.on('touchmove', (e) => {
        if (dragStartPos.current && e.points.length > 0) {
          const [startX, startY] = dragStartPos.current;
          const dx = e.points[0].x - startX;
          const dy = e.points[0].y - startY;
          if (Math.sqrt(dx * dx + dy * dy) > DRAG_THRESHOLD) {
            setIsDragging(true);
          }
        }
      });

    } catch (error) {
      console.error('Error fetching parcel data:', error);
    }
  };

  return (
    <Box position="relative">
<Box 
  sx={{ 
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    mb: 2,
  }}
>
  <Autocomplete
    options={parcels}
    getOptionLabel={(option) => option.name}
    value={selectedParcel || null}
    onChange={(event, newValue) => {
      setSelectedParcel(newValue);
      if (newValue) {
        flyToParcel(newValue);
      }
    }}
    renderOption={(props, option) => (
      <li {...props} key={option.id}>{option.name}</li>
    )}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Selecciona una parcela"
        variant="outlined"
        size="small"
        value={params.inputProps.value || ""}
        sx={{
          backgroundColor: 'white',
          width: '300px',
          '& .MuiInputBase-root': {
            height: '40px',
          },
          '& .MuiOutlinedInput-input': {
            padding: '8px 14px',
          },
          '& .MuiInputLabel-outlined': {
            transform: 'translate(14px, 11px) scale(1)',
          },
          '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
        }}
      />
    )}
  />
</Box>      
<Box position="relative">
<Box ref={mapContainer} width="100%" height="400px" />

{/* Texto de instrucciones */}
<Box
  position="absolute"
  bottom={10}
  left={10}
  bgcolor="rgba(255, 255, 255, 0.8)"
  p={1}
  borderRadius={1}
  boxShadow={1}
  zIndex={1}
>
  <Typography variant="body2">
    Para ver parcelas, acercar el zoom y desplazar.
  </Typography>
</Box>


        {showInfoParcel && parcelProperties && (
          <InfoParcel
            open={showInfoParcel}
            onClose={() => setShowInfoParcel(false)}
            onSave={handleSave}
            selectedParcel={parcelProperties}
          />
        )}

        {showInfoMyParcel && selectedMyParcel && (
          <InfoMyParcel
          open={showInfoMyParcel}
          onClose={() => setShowInfoMyParcel(false)}
          selectedParcel={selectedMyParcel}
          onEdit={handleEditParcel}
          onAddWork={handleAddWork}
        />
        
      )}

          <EditParcel
          open={isEditParcelModalOpen}
          onClose={() => setIsEditParcelModalOpen(false)}
          parcelId={selectedMyParcel?.id || null}
          initialName={selectedMyParcel?.name || ''}
          onEditSuccess={refreshParcels}
        />

        <AddWork
          open={isAddWorkModalOpen}
          onClose={() => setIsAddWorkModalOpen(false)}
          parcel={selectedMyParcel}
          onWorkAdded={handleWorkAdded}
        />

      </Box>
      
      {drawnPolygon && (
        <Box
          position="absolute"
          top="50px"
          right="100px"
          zIndex={1}
          bgcolor="white"
          p={2}
          borderRadius={1}
          boxShadow={3}
        >
          <Typography variant="h6">Nueva Parcela</Typography>
          <TextField
            label="Nombre"
            value={newParcelName || ""}
            onChange={(e) => setNewParcelName(e.target.value)}
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateParcel}
            fullWidth
          >
            Crear Parcela
          </Button>
          {apiResponse && <Typography>{apiResponse}</Typography>}
        </Box>
      )}
    </Box>
  );
};


export default MapComponent;