import * as React from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import IconButton from '@mui/joy/IconButton';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import CircularProgress from '@mui/joy/CircularProgress';
import { useNavigate, Link as RouterLink } from 'react-router-dom';  // Asegúrate de importar RouterLink
import { registerUser, createToken } from '../../sdk';  

const cookies = new Cookies();

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  password: HTMLInputElement;
}
interface SignUpFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export default function JoySignUpTemplate() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');

  const handleSubmit = async (event: React.FormEvent<SignUpFormElement>) => {
    event.preventDefault();
    setLoading(true); // Mostrar el spinner
    const formElements = event.currentTarget.elements;
    const data = {
      email: formElements.email.value,
      password: formElements.password.value,
    };

    const handleErrorResponse = (errorData: any): string => {
      // Comprobación y traducción de errores relacionados con la contraseña
      if (errorData.password) {
        for (const error of errorData.password) {
          if (error === "This password is too short. It must contain at least 8 characters.") {
            return "La contraseña es demasiado corta. Debe contener al menos 8 caracteres.";
          } else if (error === "This password is too common.") {
            return "La contraseña es demasiado común.";
          } else if (error === "The password is too similar to the username.") {
            return "La contraseña es demasiado similar al nombre de usuario.";
          }
        }
      }
    
      // Comprobación y traducción de errores relacionados con el username
      if (errorData.username) {
        for (const error of errorData.username) {
          if (error === "A user with that username already exists.") {
            return "Ya existe un usuario con ese nombre de usuario.";
          }
        }
      }
    
      // Aquí puedes añadir más comprobaciones para otros campos si es necesario
    
      // Si no se encuentran errores específicos, devuelve un mensaje genérico
      return errorData.detail || "Error desconocido. Inténtalo de nuevo.";
    };
    
  try {
    // 1. Registrar al usuario
    const registerResponse = await registerUser(data);

    // Verificar si el registro fue exitoso (código 2xx)
    if (registerResponse.status >= 200 && registerResponse.status < 300) {
      // 2. Hacer login automáticamente después del registro exitoso
      try {
        const loginResponse = await createToken(data);

        // Guardar el token en cookies
        cookies.set('token', loginResponse.access, { path: '/', expires: new Date(Date.now() + 86400000) });

        // Redirigir al usuario a /dashboard
        setError('');
        setLoading(false); // Ocultar el spinner
        navigate('/dashboard');
      } catch (loginError: any) {
        // Manejo de errores en el login
        const loginErrorMessage = loginError.response?.data?.detail || 'Error al iniciar sesión. Inténtalo de nuevo.';
        setError(loginErrorMessage);
        setLoading(false); // Ocultar el spinner
      }
    } else {
      // Manejo de errores en el registro con mensaje específico del servidor
      const registerErrorMessage = handleErrorResponse(registerResponse.data);
      setError(registerErrorMessage);
      setLoading(false); // Ocultar el spinner
    }
  } catch (err: any) {
    console.log(err.response.data);

    // Manejo de errores de la respuesta en la llamada de registro
    const registerErrorMessage = handleErrorResponse(err.response.data);
    setError(registerErrorMessage);
    setLoading(false); // Ocultar el spinner
  }
    


  }
  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s',
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width: { xs: '100%', md: '50vw' },
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box 
              component={RouterLink} // Usamos RouterLink para la navegación
              to="/" 
              sx={{ gap: 2, display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}
            >
              <IconButton variant="soft" color="primary" size="sm">
                <BadgeRoundedIcon />
              </IconButton>
              <Typography level="title-lg">Cultiva Data</Typography>
            </Box>
          </Box>
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: 'hidden',
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography component="h1" level="h3">
                  Registrarse
                </Typography>
              </Stack>
            </Stack>
            <Divider
              sx={(theme) => ({
                [theme.getColorSchemeSelector('light')]: {
                  color: { xs: '#FFF', md: 'text.tertiary' },
                },
              })}
            ></Divider>
            <Stack gap={4} sx={{ mt: 2 }}>
              <form onSubmit={handleSubmit}>
                <FormControl required>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" name="email" />
                </FormControl>
                <FormControl required>
                  <FormLabel>Contraseña</FormLabel>
                  <Input type="password" name="password" />
                </FormControl>
                <Stack gap={4} sx={{ mt: 2 }}>
                  {error && <Typography color="danger" sx={{ fontWeight: 'bold', color: 'darkred' }}>{error}</Typography>}
                  <Button type="submit" fullWidth disabled={loading}>
                    {loading ? <CircularProgress size="sm" /> : 'Registrarse'}
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © Cultivadata {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: { xs: 0, md: '50vw' },
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: 'url(/images/landingSignIn.jpg)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage: 'url(/images/landingSignIn.jpg)',
          },
        })}
      />
    </CssVarsProvider>
  );
}
