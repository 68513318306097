import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem } from '@mui/material';
import { Machinery } from '../../sdk';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import es from 'date-fns/locale/es';

interface MachineryDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (formData: Omit<Machinery, 'id'>) => void;
  currentMachinery: Machinery | null;
}

const TIPOS_MAQUINARIA = [
  "TRACTORES",
  "TRACTOCARROS",
  "MAQUINARIA ARRASTRADA O SUSPENDIDA",
  "MAQUINARIA AUTOMOTRIZ",
  "MAQUINARIA DE PREPARACIÓN Y TRABAJO DEL SUELO",
  "MAQUINARIA DE RECOLECCIÓN",
  "MOTOCULTORES Y MOTOMÁQUINAS",
  "EQUIPOS DE CARGA",
  "EQUIPOS DE RECOLECCIÓN",
  "EQUIPOS DE TRATAMIENTOS",
  "EQUIPOS PARA APORTE DE FERTILIZANTES Y AGUA",
  "EQUIPOS PARA SIEMBRA Y PLANTACIÓN",
  "REMOLQUES",
  "OTROS",
];

const OWNERSHIP_OPTIONS = [
  { value: 'OWN', label: 'Propia' },
  { value: 'RENTED', label: 'Alquilada' },
];

const AddMachinery: React.FC<MachineryDialogProps> = ({ open, onClose, onSubmit, currentMachinery }) => {
  const [formData, setFormData] = React.useState<Omit<Machinery, 'id'>>({
    roma_number: '',
    type: '',
    brand: '',
    model: '',
    ownership: 'OWN',
    date_of_purchase: '',
    date_of_revision: '',
  });

  React.useEffect(() => {
    if (currentMachinery) {
      setFormData(currentMachinery);
    } else {
      setFormData({
        roma_number: '',
        type: '',
        brand: '',
        model: '',
        ownership: 'OWN',
        date_of_purchase: '',
        date_of_revision: '',
      });
    }
  }, [currentMachinery]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (name: string) => (date: Date | null) => {
    setFormData(prev => ({ ...prev, [name]: date ? date.toISOString().split('T')[0] : undefined }));
  };

  const handleSubmit = () => {
    onSubmit(formData);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{currentMachinery ? 'Editar Maquinaria' : 'Añadir Maquinaria'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="roma_number"
          label="Número ROMA"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.roma_number}
          onChange={handleInputChange}
        />
        <TextField
          select
          margin="dense"
          name="type"
          label="Tipo"
          fullWidth
          variant="outlined"
          value={formData.type}
          onChange={handleInputChange}
        >
          {TIPOS_MAQUINARIA.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="dense"
          name="brand"
          label="Marca"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.brand}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="model"
          label="Modelo"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.model}
          onChange={handleInputChange}
        />
        <TextField
          select
          margin="dense"
          name="ownership"
          label="Propiedad"
          fullWidth
          variant="outlined"
          value={formData.ownership}
          onChange={handleInputChange}
        >
          {OWNERSHIP_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="dense"
          name="date_of_purchase"
          label="Fecha de adquisición"
          type="date"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={formData.date_of_purchase}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="date_of_revision"
          label="Fecha de última revisión"
          type="date"
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={formData.date_of_revision}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit}>Guardar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMachinery;