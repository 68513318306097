import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Grid, IconButton, Autocomplete, Tooltip, ToggleButton, ToggleButtonGroup } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MapIcon from '@mui/icons-material/Map';
import { createParcel, createCrop, getFavouriteCrops, FavouriteCrop } from '../../sdk';
import getParcelData, { MunicipioInfo } from '../parcel/ParcelData';
import AddCropDialog from './AddCropDialog';

interface SelectParcelProps {
  open: boolean;
  onClose: () => void;
  onSave: (data: { text: string; selectedOption: string }) => void;
  selectedParcel: any;
}


const InfoParcel: React.FC<SelectParcelProps> = ({ open, onClose, onSave, selectedParcel }) => {
  const [text, setText] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [municipioInfo, setMunicipioInfo] = useState<MunicipioInfo | null>(null);
  const [parcelProperties, setParcelProperties] = useState({
    dn_surface: 0,
    provincia: 0,
    municipio: 0,
    poligono: 0,
    agregado: 0,
    parcela: 0,
    zona: 0,
    coordinates: [] as [number, number][], 
  });
  const [species, setSpecies] = useState<string | null>(null);
  const [variety, setVariety] = useState<string | null>(null);
  const [speciesError, setSpeciesError] = useState(false);
  const [irrigationType, setIrrigationType] = useState<'secano' | 'regadio' | null>(null);
  const [irrigationError, setIrrigationError] = useState(false);
  const [speciesList, setSpeciesList] = useState<string[]>([]);
  const [isAddCropDialogOpen, setIsAddCropDialogOpen] = useState(false);

  useEffect(() => {
    const fetchFavouriteCrops = async () => {
      try {
        const response = await getFavouriteCrops();
        const favouriteCropNames = response.crops.map((crop: FavouriteCrop) => crop.name);
        setSpeciesList(favouriteCropNames);
      } catch (error) {
        console.error('Error al obtener los cultivos favoritos:', error);
        // Aquí podrías manejar el error, por ejemplo, mostrando un mensaje al usuario
      }
    };

    fetchFavouriteCrops();
  }, []);

  useEffect(() => {
    if (selectedParcel) {
      setText(selectedParcel.name || '');
      setSelectedOption(selectedParcel.area || null);
      setParcelProperties({
        dn_surface: selectedParcel.dn_surface || 0,
        provincia: selectedParcel.provincia || 0,
        municipio: selectedParcel.municipio || 0,
        poligono: selectedParcel.poligono || 0,
        agregado: selectedParcel.agregado || 0,
        parcela: selectedParcel.parcela || 0,
        zona: selectedParcel.zona || 0,
        coordinates: selectedParcel.coordinates || [],
      });
    }
  }, [selectedParcel]);

  useEffect(() => {
    const info = getParcelData({
      provincia: parcelProperties.provincia,
      municipio: parcelProperties.municipio
    });
    setMunicipioInfo(info);
  }, [parcelProperties.provincia, parcelProperties.municipio]);

  const convertToHectares = (surface: number): number => {
    return parseFloat((surface / 10000).toFixed(2));
  };

  const handleIrrigationChange = (
    event: React.MouseEvent<HTMLElement>,
    newIrrigationType: 'secano' | 'regadio' | null,
  ) => {
    setIrrigationType(newIrrigationType);
    if (irrigationError) setIrrigationError(false);
  };

  const handleSave = async () => {
    let hasError = false;

    if (!text.trim()) {
      setError(true);
      hasError = true;
    }
    if (!species) {
      setSpeciesError(true);
      hasError = true;
    }
    if (!irrigationType) {
      setIrrigationError(true);
      hasError = true;
    }

    if (hasError) return;

    const parcelData = {
      name: text,
      area: parcelProperties.dn_surface,
      agromonitoring_id: '1111',
      provincia: parcelProperties.provincia,
      municipio: parcelProperties.municipio,
      agregado: parcelProperties.agregado,
      zona: parcelProperties.zona,
      poligono: parcelProperties.poligono,
      parcela: parcelProperties.parcela,
      superficie: convertToHectares(parcelProperties.dn_surface),
      coordinates: parcelProperties.coordinates,
      species: species || '',
      variety: variety || undefined,
      irrigation_type: irrigationType,
    };

    try {
      const response = await createParcel(parcelData);
      console.log("EL ID DE LA PARCELA ES", response.data.id);
      
      // Crear el cultivo después de crear la parcela
      const cropData = {
        parcel: response.data.id,
        species: species || '',
        variety: variety || undefined,
        irrigation_type: irrigationType || undefined,
      };

      try {
        const cropResponse = await createCrop(cropData);
        console.log("Cultivo creado con ID:", cropResponse.data.id);
      } catch (cropError) {
        console.error('Error al crear el cultivo:', cropError);
      }
      
      onSave({ text, selectedOption: selectedOption || '' });
      onClose();
    } catch (error) {
      console.error('Error al guardar la parcela:', error);
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.substring(0, maxLength - 3) + '...' : text;
  };

  const handleAddCropClick = () => {
    setIsAddCropDialogOpen(true);
  };

  const handleCropAdded = () => {
    // Actualizar la lista de cultivos favoritos
    const fetchFavouriteCrops = async () => {
      try {
        const response = await getFavouriteCrops();
        const favouriteCropNames = response.crops.map((crop: FavouriteCrop) => crop.name);
        setSpeciesList(favouriteCropNames);
      } catch (error) {
        console.error('Error al obtener los cultivos favoritos:', error);
        // Aquí podrías manejar el error, por ejemplo, mostrando un mensaje al usuario
      }
    };

    fetchFavouriteCrops();
  };

  if (!open) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: 'background.paper',
        p: 2,
        borderRadius: '20px 20px 0px 0px',
        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
        maxHeight: '80vh',
        overflowY: 'auto',
        zIndex: 1300,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Box display="flex" alignItems="center">
          <Typography variant="h6" fontWeight="bold" mr={2}>Registrar Parcela</Typography>
          <Tooltip title="Superficie de la parcela">
            <Box display="flex" alignItems="center" sx={{ bgcolor: 'rgba(0, 0, 0, 0.05)', borderRadius: 1, px: 1, py: 0.5 }}>
              <MapIcon fontSize="small" sx={{ mr: 0.5, color: 'primary.main' }} />
              <Typography variant="body2" fontWeight="medium">
                {convertToHectares(parcelProperties.dn_surface)} ha
              </Typography>
            </Box>
          </Tooltip>
        </Box>
        <IconButton onClick={onClose} color="primary" sx={{ bgcolor: 'rgba(0, 0, 0, 0.05)' }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Nombre Parcela"
            value={text}
            onChange={(e) => {
              setText(e.target.value);
              if (error) setError(false);
            }}
            fullWidth
            required
            error={error}
            helperText={error ? 'El nombre de la parcela es obligatorio' : ''}
            size="small"
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            options={speciesList}
            value={species}
            onChange={(event, newValue) => {
              setSpecies(newValue);
              if (speciesError) setSpeciesError(false);
            }}
            renderInput={(params) => (
              <TextField 
                {...params} 
                label="Especie" 
                size="small" 
                required
                error={speciesError}
                helperText={speciesError ? 'La especie es obligatoria' : ''}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Variedad (Opcional)"
            value={variety}
            onChange={(e) => setVariety(e.target.value)}
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>
      <Typography 
        variant="caption" 
        component="div" 
        sx={{ 
          mt: 0.5, 
          fontSize: '0.7rem', 
          color: 'text.secondary',
          cursor: 'pointer',
          width: '100%',
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
        onClick={handleAddCropClick}
      >
        ¿No encuentras tu cultivo? Haz clic aquí
      </Typography>

      <AddCropDialog 
        open={isAddCropDialogOpen} 
        onClose={() => setIsAddCropDialogOpen(false)}
        onCropAdded={handleCropAdded}
      />

      <Grid container spacing={1} mt={1}>
        <Grid item xs={12}>
          <Typography variant="body2" gutterBottom>Tipo de riego*</Typography>
          <ToggleButtonGroup
            value={irrigationType}
            exclusive
            onChange={handleIrrigationChange}
            aria-label="tipo de riego"
            fullWidth
            size="small"
          >
            <ToggleButton 
              value="secano" 
              aria-label="secano"
              sx={{
                py: 0.5,
                fontSize: '0.875rem',
                '&.Mui-selected': {
                  backgroundColor: 'success.light',
                  color: 'success.contrastText',
                  '&:hover': {
                    backgroundColor: 'success.main',
                  },
                },
                border: irrigationError ? '1px solid red' : undefined,
              }}
            >
              Secano
            </ToggleButton>
            <ToggleButton 
              value="regadio" 
              aria-label="regadío"
              sx={{
                py: 0.5,
                fontSize: '0.875rem',
                '&.Mui-selected': {
                  backgroundColor: 'success.light',
                  color: 'success.contrastText',
                  '&:hover': {
                    backgroundColor: 'success.main',
                  },
                },
                border: irrigationError ? '1px solid red' : undefined,
              }}
            >
              Regadío
            </ToggleButton>
          </ToggleButtonGroup>
          {irrigationError && (
            <Typography color="error" variant="caption" sx={{ mt: 0.5 }}>
              Selecciona un tipo de riego
            </Typography>
          )}
        </Grid>
      </Grid>

      <Box mt={2} sx={{ display: 'flex', justifyContent: 'space-between', textAlign: 'center' }}>
        {['Polígono', 'Parcela', 'Agregado', 'Zona'].map((label) => (
          <Box key={label} sx={{ flex: 1 }}>
            <Typography variant="body2" fontWeight="bold">{label}</Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', textAlign: 'center' }}>
        {['poligono', 'parcela', 'agregado', 'zona'].map((key) => (
          <Box key={key} sx={{ flex: 1 }}>
            <Typography variant="body2">
              {parcelProperties[key as keyof typeof parcelProperties]}
            </Typography>
          </Box>
        ))}
      </Box>

      <Grid container spacing={1} mt={1}>
        <Grid item xs={6}>
          <Tooltip title={municipioInfo ? municipioInfo.nombre_provincia : 'No encontrado'}>
            <Typography variant="body2" noWrap>
              <strong>Provincia:</strong> {municipioInfo 
                ? `${municipioInfo.id_provincia} ${truncateText(municipioInfo.nombre_provincia, 20)}`
                : 'No encontrado'}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <Tooltip title={municipioInfo ? municipioInfo.nombre_municipio : 'No encontrado'}>
            <Typography variant="body2" noWrap>
              <strong>Municipio:</strong> {municipioInfo 
                ? `${municipioInfo.id_municipio} ${truncateText(municipioInfo.nombre_municipio, 20)}`
                : 'No encontrado'}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>

      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleSave} 
        fullWidth 
        sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}
      >
        Guardar Parcela
      </Button>
    </Box>
  );
};

export default InfoParcel;
