import municipiosData from '../../data/municipios.json';

export interface MunicipioInfo {
  id_municipio: number;
  nombre_municipio: string;
  id_provincia: number;
  nombre_provincia: string;
}

const buscarMunicipioYProvincia = (idProvincia: number, idMunicipio: number): MunicipioInfo | null => {
  const municipio = municipiosData.find(
    (m: any) => m.Provincia === idProvincia && m.Municipio === idMunicipio
  );

  if (municipio) {
    return {
      id_municipio: municipio.Municipio,
      nombre_municipio: municipio["Nombre Municipio"],
      id_provincia: municipio.Provincia,
      nombre_provincia: municipio["Nombre Provincia"]
    };
  }

  return null;
};

interface ParcelDataProps {
  provincia: number;
  municipio: number;
}

export const getParcelData = ({ provincia, municipio }: ParcelDataProps): MunicipioInfo | null => {
  return buscarMunicipioYProvincia(provincia, municipio);
};

export default getParcelData;
